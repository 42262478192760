import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { IExplain, setExplain } from '../../features/userInfo/bobetteSlice';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
interface P2_ExplainProps {

}

export default function P2_Explain({ }: P2_ExplainProps) {
    const navigate = useNavigate()
    const [formData, setFormData] = useState<IExplain>({projectName: '', receiverName: '', receiverSecondName: '', deadline: ''});
    const dispatch = useDispatch()

    const handleDeadlineChange = (date: Date | null) => {
        if(date!=null)
        setFormData({ ...formData, deadline: date });
      };

      const isFormDataUnComplete = () => {
        // Check if all required fields are filled
        if (!formData.projectName || 
            !formData.receiverName || 
            !formData.receiverSecondName || 
            !formData.deadline ||
            formData.projectName.length < 3 ||
            formData.receiverName.length < 3 ||
            formData.receiverSecondName.length < 3
            
            ) {
          return true;
        }
        
        return false;
      };

    return (
        <>
            <div className="pt-1.5">
                <h1 className="text-3xl font-bold text-gray-900">Expliquer mon  </h1>
                <h1 className="text-3xl font-bold text-gray-900">projet</h1>

                {/* <p className="text-sm font-medium text-gray-500">
                    Description Titre
                </p> */}
            </div>
            <div className='mt-6 flex flex-col gap-4'>
                <div className="lg:col-start-3 sm:col-span-1">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                        Titre du projet
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            placeholder='Mon projet'
                            autoComplete=""
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        /*  value={formData.vmakerSecondName || ''} */
                         onChange={(e) => { setFormData({ ...formData, projectName: e.target.value }); 
                        }} 
                        />
                    </div>
                </div>
                <div className="lg:col-start-3 sm:col-span-1">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                        Renseignez le destinataire
                    </label>
                    <div className="mt-2 flex justify-between gap-2">
                        <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            placeholder='Nom'
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                         onChange={(e) => { setFormData({ ...formData, receiverSecondName: e.target.value }); }}

                            /*  value={formData.vmakerSecondName || ''} */
                        /* onChange={(e) => { setFormData({ ...formData, vmakerSecondName: e.target.value }); 
                        setErrorFields('') }} */
                        />
                        <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder='Prénom'

                            autoComplete="name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                         onChange={(e) => { setFormData({ ...formData, receiverName: e.target.value }); }}

                            /*  value={formData.vmakerSecondName || ''} */
                        /* onChange={(e) => { setFormData({ ...formData, vmakerSecondName: e.target.value }); 
                        setErrorFields('') }} */
                        />
                    </div>
                </div>
                <div className="lg:col-start-3 sm:col-span-1">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                        Date limite de participation
                    </label>
                    <div className="mt-2">

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker 
                         onChange={(e:Date|null) => handleDeadlineChange(e)}
                            
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <button
                    type="button"
                    className="mt-6 rounded-full w-44 bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={()=>{dispatch(setExplain(formData)); navigate('/bobette/connexion')}}
                    disabled={isFormDataUnComplete()}
                    style={isFormDataUnComplete()? {backgroundColor:'gray', opacity:0.4}:{}}
                >
                    Continuer
                </button>
            </div>
        </>
    )
}