import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'


export interface ILogs {
    myLogs: Array<string>
}

const initialState : ILogs = {
    myLogs: ['']
}

export const logsSlice = createSlice({
    name: 'currentLogs',
    initialState,
    reducers:{
        setLogs : (state, action: PayloadAction<string>) =>{
            state.myLogs.push(action.payload + ',' + ' ')
        }
    }
})

export const { setLogs } = logsSlice.actions

export const selectLogs = (state:RootState) => state.currentLogs.myLogs

export default logsSlice.reducer