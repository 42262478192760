//https://stackoverflow.com/questions/67828731/react-webcam-recording-replay-re-recording-storing

import React, { MutableRefObject, RefCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Navigate, useNavigate } from "react-router-dom";
import type { RootState } from '../app/store'
import { useSelector, useDispatch } from "react-redux";
import { setBlobArray } from "../features/blob/blobSlice";
import { selectLogs, setLogs } from "../features/mobileLogs/logsSlice";
import Axios from "axios";
import { setCloudinaryLink } from "../features/ios/videoLinkSlice";
import { Cloudinary, CloudinaryVideo, Transformation } from '@cloudinary/url-gen';
import UserAgent from 'user-agents';
import vmakerSlice, { selectCurrentVmaker } from "../features/userInfo/vmakerSlice";


const cld = new Cloudinary({
  cloud: {
    cloudName: 'dxq4veqsa',
  }
});


export const StartYourRecording = () => {
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder>();
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Array<string>>([]);
  const isInitialMount = useRef(true);
  const inputRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  // const selectVideoLink = useSelector((state: RootState) => state.currentVideoLink.value)
  const selectMyCurrentLogs = useSelector(selectLogs)
  const selectVmaker = useSelector(selectCurrentVmaker)
  const [progressVal, setProgressVal] = useState<number>(0)

  const [currentFragment, setCurrentFragment] = useState(1)

  
  const requestOptions: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Origin': 'http://localhost:3000',

    },

};

const [textFragments, setTextFragments] = useState<Array<{text:string,textIndex:number}>>([{text:'',textIndex:0}])

  const textFragmentArray = [
    [
      {
        text: "Text 1",
        description: "Description 1"
      },
      {
        text: "Text 2",
        description: "Description 2"
      }
    ],
    [
      {
        text: "",
        description: "Description 3"
      },
      {
        text: "Text 4",
        description: "Description 4"
      }
    ],
    // Add more arrays or objects as needed
  ];

  const dispatch = useDispatch()

  const addlogs = (newlog: string) => {
    dispatch(setLogs(newlog))
  }

  
    useEffect(() => {

      // change bobette ID by a short uuid (shared to all vmakers) : https://www.npmjs.com/package/short-uuid
      // fetching the with ID of Bobette
      //  console.log(id)


      fetch(`https://bobette2022-002-site1.ftempurl.com/getTextFragments?vmakerId=${selectVmaker.vmakerId}`, requestOptions)
          .then(response => response.json())
          .then(function (data) {
              console.log(data);
              // firstTry
              const tempArray=[]
              for (const textFrag of data) {
                 tempArray.push({text:textFrag.content,textIndex:textFrag.textIndex})
              }
              const sortedArray = tempArray.sort((a, b) => a.textIndex - b.textIndex);
              console.log(tempArray)
              setTextFragments(sortedArray)
          })



  }, [])
  


  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (!capturing) {
        console.log('running handleDownload')
        handleDownload();
      }
    }
  }, [recordedChunks])

  const handleStartCaptureClick = React.useCallback(() => {
    try {

      setCapturing(true);
      addlogs('setCaptureToTrue')
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current!.stream!, {
        mimeType: "video/webm"
      });
      addlogs('added new MediaRecorder')
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      addlogs('added Event Listener on current')
      mediaRecorderRef.current.start();
      addlogs('startCapture')
    } catch (error) {
      console.log(error)
      inputRef.current!.click()
      setCapturing(false)
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    // BlobEvent Type?
    ({ data }: any) => {
      addlogs('handleDataAvailable')
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current!.stop();
    addlogs('stopCapture')
    addlogs(mediaRecorderRef.current!.state)

    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = React.useCallback(() => {
    console.log(recordedChunks);

    if (recordedChunks.length) {
      navigate('/previewRecorded')
      //   console.log("heyChunck");


      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      dispatch(setBlobArray({ value: [blob] }))

    }
  }, [recordedChunks]);

  useEffect(() => {
    console.log('inputRef')
    if (inputRef.current?.onclose) {
      console.log('hey')
      alert('op')
    }

  }, [inputRef.current?.formAction])

  const uploadIosVideo = (recivedfile: HTMLInputElement["files"]) => {
    console.log(recivedfile![0])
    const file = recivedfile![0]

    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "gzllmk5l")
    Axios.post("https://api.cloudinary.com/v1_1/dxq4veqsa/upload", formData, {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
        console.log(percentCompleted);
        setProgressVal(progressVal + percentCompleted)
      }
    })
      .then((response) => {
        console.log(response)
        console.log('v' + response.data.version + '/' + response.data.public_id)
        /*  createVlink('v' + response.data.version + '/' + response.data.public_id) */
        dispatch(setCloudinaryLink({ value: 'v' + response.data.version + '/' + response.data.public_id , duration: response.data.duration  }))
        console.log(response.status)
        console.log(response.data)
        navigate('/previewRecorded')
      })

  }
  const [userAgent, setUserAgent] = useState<UserAgent>()
  useEffect(() => {
    const newAgent = new UserAgent()
    console.log(newAgent.data)
    setUserAgent(newAgent)
    if (userAgent) {

      console.log(userAgent.toString());
      console.log(JSON.stringify(userAgent.data, null, 2));
    }
  }, [])



  return (
    <>
      <div className="h-screen w-full overflow-x-hidden">
        <div className="flex justify-center">
          {/*   <img className='absolute w-42 h-18 m-1' src='./../fluo.png' ></img>
 */}
          <h1 className=" w-48 z-10 pt-3 self-center z-10 text-secondary-content pt-1 mb-5">Commence ton message video :</h1>
        </div>
        
        <div className="flex flex-col p-4 justify-around h-full ">
          <div className="flex flex-col">
            {
              navigator.userAgent.concat().includes('iPhone') ? (
                <>
                  <div className="flex flex-col gap-1">
                    Voici les instructions:<br></br>
                    1 Sélectionne 'Prendre photo ou vidéo'
                    <img className="h-24 w-64" src="/step1.png" alt="vidéo click" />
                    2 Sélectionne la vidéo pour enregistrer ton extrait
                    <img className="h-24" src="/step2.png" alt="enregistrement vidéo" />
                    3 Appuie sur utiliser
                    <img className="h-24" src="/step3.png" alt="utiliser" />

                  </div>
                </>
              )
                :
                <Webcam className="border-0 rounded-xl" muted={true} audio={true} ref={webcamRef} />
            }
            {/* <video id="video-replay" height="400" width="500" controls></video> */}
            {capturing ? (
              <button className="w-48 mt-4 self-center bg-colorB1 text-white drop-shadow-lg p-3 rounded-lg text-slate-100 uppercase flex justify-around" onClick={handleStopCaptureClick}>Arrêter</button>
            ) : (
              <button className="w-48 mt-4 self-center bg-colorB1 text-white drop-shadow-lg p-3 rounded-lg text-slate-100 uppercase flex justify-around" onClick={handleStartCaptureClick}>Enregistrer</button>
            )}
          </div>
          {progressVal > 0 ?
            <div className='flex flex-col mx-auto w-2/3 gap-2'>

              <h2>Video en préparation, ne pas quitter la page</h2>
              <p className="self-center">{progressVal}</p>
              <progress className="progress progress-info w-56 m-2" value={progressVal} max="100"></progress>
            </div>
            : ''
          }
          <input
            ref={inputRef!}
            type="file"
            accept="video/*;capture=camcorder"
            style={{ opacity: 0 }}
            onChange={(e) => {
              addlogs(e.target.files![0].type.toString());
              uploadIosVideo(e.target.files!)
              // upload file in cloudinary for preview
              // dispatch as a link 
              // dispatch(setBlobArray({ value: [new Blob([e.target.files![0]])] }));

            }}
          >
          </input>

          {recordedChunks.length > 0 && (
            <div>
              <button onClick={handleDownload}>Download</button>
            </div>
          )}
        </div>
        <div>

          {/*    {userAgent?userAgent.data.platform.toString():''} */}
          { }
        </div>
        {/*  <div style={{ border: 'solid 2px', width: '50rem' }}>
          <p>Message </p>
          <div>
            {selectMyCurrentLogs}
          </div>
        </div> */}
      </div>
    </>
  );
};