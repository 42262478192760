import { CalendarDaysIcon, CreditCardIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setTypeOfEvent } from '../../features/userInfo/bobetteSlice'

interface P1_CreateProps {

}

export default function P1_Create({ }: P1_CreateProps) {
    const navigate = useNavigate()
    const dispatch = useDispatch()


    return (
        <>
            <div className="md:flex md:items-center md:justify-between md:space-x-5">
                <div className="flex flex-col items-start space-x-5">

                    {/*
          Use vertical padiving to simulate center alignment when both lines of text are one line,
          but preserve the same layout if the text wraps without making the image jump around.
        */}
                    <div className="pt-1.5">
                        <h1 className="text-3xl font-bold text-gray-900">Créer une vidéo </h1>
                        <h1 className="text-3xl font-bold text-gray-900"> à plusieurs</h1>

                        <p className="text-sm font-medium text-gray-500">
                            Choisir le type d'évènement
                        </p>
                    </div>

                    <div className='flex flex-col'>

                        <div className="cursor-pointer group mt-6 flex border border-2 rounded-md w-full gap-x-4 px-6 p-4 py-auto" onClick={() => { navigate('/bobette/explain'); dispatch(setTypeOfEvent('Anniversaire')); }}>
                            <div className="self-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="opacity-50 w-6 h-6 group-hover:opacity-100">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
                                </svg>
                            </div>
                            <div className="  opacity-70 group-hover:opacity-100 text-sm font-medium leading-6 text-gray-600">Anniversaire</div>
                        </div>
                        <div className="cursor-pointer group mt-6 flex border border-2 rounded-md w-full gap-x-4 px-6 p-4 py-auto" onClick={() =>{ navigate('/bobette/explain'); dispatch(setTypeOfEvent('Pot de départ')); }}>

                            <div className="flex-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="opacity-50 w-6 h-6 group-hover:opacity-100" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
                                </svg>

                            </div>
                            <div className=" opacity-70 group-hover:opacity-100 text-sm font-medium leading-6 text-gray-600">
                                Pot de départ
                            </div>
                        </div>
                        <div className="cursor-pointer group mt-6 flex border border-2 rounded-md w-full gap-x-4 px-6 p-4 py-auto" onClick={() => { navigate('/bobette/explain'); dispatch(setTypeOfEvent('Autre')); }}>
                            <div className="flex-none">
                                <span className="sr-only">Status</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="opacity-50 w-6 h-6 group-hover:opacity-100">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                </svg>

                            </div>
                            <div className="opacity-70 group-hover:opacity-100 text-sm font-medium leading-6 text-gray-600">Autre</div>
                        </div>
                    </div>
                </div>



                {/*  <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        Disqualify
                    </button>
                    <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Advance to offer
                    </button>
                </div> */}
            </div>
        </>
    )
}
