import Axios from "axios";
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IInvitation, setInvitation } from "../../features/userInfo/bobetteSlice";
import { useDispatch } from "react-redux";

interface P4_InvitationProps {

}


// Ajouter l'invitation en DB

export default function P4_Invitation({ }: P4_InvitationProps) {
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
 // const [isLoading, setIsLoading] = useState(false)
  const [srcImage, setSrcImage] = useState('')
  const [currentblob, setcurrentBlob] = useState()
  const defaultText = 'Nous avons besoin de vous ! L’idée est d’enregistrer un court témoignage vidéo pour XXX à l’occasion de XXX.\nPour cela, prenez quelques instants pour lui adresser vos vœux, partager vos souvenirs et rendre ainsi cette occasion encore plus mémorable.\n1000 mercis 🌷'
  const [myForm, setMyForm] = useState<IInvitation>({ image: '', invitationTitle: 'Participez à une belle surprise 🎁', invitationDescription: defaultText, });
  const dispatch = useDispatch()



  const handleClick = () => {
    inputRef.current!.click()
  }

   const handleClose = () => {
    inputRef.current!.value = ''
  } 

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setcurrentBlob(file)
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        // Set the image source for preview
        if (reader.result)
          setSrcImage(reader.result.toString());
      };

      // Read the file as a data URL (base64 string)
      reader.readAsDataURL(file);
    }
  };


  const confirmAndUpload = () => {

   

    if(currentblob){


      const file = currentblob
      const formData = new FormData()
      formData.append('file', file)
      formData.append("upload_preset", "gzllmk5l")
      Axios.post("https://api.cloudinary.com/v1_1/dxq4veqsa/upload", formData, {
        onUploadProgress: progressEvent => {
          /*   const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
            console.log(percentCompleted); */
        //  setIsLoading(true)
          //   setProgressVal(progressVal + percentCompleted)
        }
      })
        .then((response) => {
         // setSrcImage(response.data.secure_url)
          setMyForm({ ...myForm, image : response.data.version + '/' + response.data.public_id})
          dispatch(setInvitation({ ...myForm, image : response.data.version + '/' + response.data.public_id}))
          navigate('/bobette/saved')

          console.log(response)
          console.log('v' + response.data.version + '/' + response.data.public_id)
          /*  createVlink('v' + response.data.version + '/' + response.data.public_id) */
          // dispatch(setCloudinaryLink({ value: 'v' + response.data.version + '/' + response.data.public_id }))
          console.log(response.status)
          console.log(response.data)
         // setIsLoading(false)
          // navigate('/previewRecorded')
        })
    }else{
      
      dispatch(setInvitation(myForm))
      navigate('/bobette/saved')

    }

  }




  return (
    <>
      <div className="pt-1.5">
        <h1 className="text-3xl font-bold text-gray-900">Personnaliser </h1>
        <h1 className="text-3xl font-bold text-gray-900">mon invitation </h1>

        <p className="text-sm font-medium text-gray-500">

          Rendez votre invitation conviviale

        </p>
        <p className="text-sm font-medium text-gray-500">

          en ajoutant une photo et un descriptif
        </p>

      </div>
      <div className="flex">

        {srcImage === '' ?
          <>
            <div className="cursor-pointer border-dashed border-2 h-16 w-52 rounded-md border-indigo-600 flex flex-col justify-around mt-6" onClick={handleClick}>

              <div className='cursor-pointer bg-white'>

                <div className=' text-center my-auto mx-auto mt-2 z-30 bg-white'>

                  Télécharger une image
                </div>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-8 h-8 self-center mb-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
              </svg>
    
            </div>
           {/*  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="animate-spin w-6 h-6 self-center ml-4 mt-3" opacity={isLoading ? 1 : 0}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg> */}
          </>
          :
          <>
          <img src={srcImage} alt="" className={srcImage != '' ? "h-40  mt-6 bg-auto bg-no-repeat rounded-lg" : "h-16 w-52 mt-6 opacity-0"} />
              <button
        className="opacity-30 mt-6 h-10 self-center ml-2 rounded-full w-44 bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={()=>handleClick}
              disabled={true}
              
              >Télécharger une photo</button>
          </>
        }
                  <input
                ref={inputRef!}
                type="file"
                accept="image/png, image/jpeg"
                className=' h-16 w-52 pt-3'
                style={{ visibility: 'hidden', position: 'absolute', right: 30, cursor: 'pointer' }}
                onChange={(e) => {
                  /* addlogs(e.target.files![0].type.toString()); */
                  handleImageChange(e)
                  handleClose()
                  //   confirmAndUpload(e.target.files!)
                  //  srcImage(e.target.files[0].type.)
                  // upload file in cloudinary for preview
                  // dispatch as a link 
                  // dispatch(setBlobArray({ value: [new Blob([e.target.files![0]])] }));

                }}
              >
              </input>
      </div>
      <div className="lg:col-start-3 sm:col-span-1 mt-3">
        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
          Titre de l'invitation
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="title"
            id="title"
            placeholder='Mon projet'
            autoComplete=""
            defaultValue={'Participez à une belle surprise 🎁'}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
         onChange={(e)=>
          setMyForm({ ...myForm, invitationTitle: e.currentTarget.value })
        
        }
          />
        </div>
      </div>
      <div className="lg:col-start-3 sm:col-span-1 mt-3">
        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
          Description
        </label>
        <div className="mt-2 flex justify-between gap-2">
          <textarea
            rows={8}
            placeholder=''
            name="comment"
            id="comment"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue={defaultText}
            onChange={(e)=>
              setMyForm({ ...myForm, invitationDescription: e.currentTarget.value })
            
            }
         />
          
        </div>
      </div>


      <button
        type="button"
        className="mt-6 rounded-full w-44 bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={() =>{
          confirmAndUpload( );
       
        }
          
          }
      >
        Continuer
      </button>
    </>
  )
}
