import React, { useState } from 'react';

interface TabProps {
  tabs: string[];
  status?: (arg:number)=>void;
}

const CustomTab: React.FC<TabProps> = ({ tabs, status }) => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    console.log(tab)
    const statutNumber = (tabs.indexOf(tab))
    console.log(statutNumber)
    
    if (statutNumber !== -1 && status) {
      status(statutNumber) 
    }
    
  };

  return (
    <div className="rounded-lg flex justify-center mb-4 bg-[#E5E5E6]  w-80">
      {tabs.map((tab) => (
        <div
          key={tab}
          style={{paddingTop:'3px'}}
          className={`flex rounded-lg w-full justify-center h-7 px-auto text-sm text-center normal-case font-["robotto"] font-light cursor-pointer ${
            selectedTab === tab ? 'bg-white text-black border border-1' : 'bg-gray-200'
          }`}
          onClick={() => handleTabClick(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default CustomTab;
