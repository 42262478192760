import React, { memo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface Video {
  id: string;
  url: string;
  start: number;
  end: number;
  duration:number;
  isArchived: boolean;
}

interface VideoListProps {
  videos: Video[];
  handleOnDragEnd: (result: any) => void;
  archiveVid: (id: string, isArchived: boolean) => void;
  LINKTOVIDEO: string;
  getDuration :(args:string) => void
  setSourceVid: React.Dispatch<React.SetStateAction<string>>
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
}


interface ArchiveListProps {
    videos: Video[];
    handleOnDragEnd: (result: any) => void;
    archiveVid: (id: string, isArchived: boolean) => void;
    LINKTOVIDEO: string;
}


export const VideoList: React.FC<VideoListProps> =  ({ videos, handleOnDragEnd, archiveVid, LINKTOVIDEO,getDuration,setSourceVid,setOpenDialog }) => {

  


    return (
      <>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='videos'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="flex flex-col">
                  {videos &&
                    videos.map((elem, index) => (
                      !elem.isArchived ?
                        <Draggable key={elem.id} draggableId={elem.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="w-96 mx-4"
                            >
                              <div className='flex gap-2'>
                                <div className='bg-white rounded-lg my-2 w-80 mx-auto'>
                                  <div className='flex h-20 gap-4 justify-center'>
                                    <video
                                      id={elem.url}
                                      src={LINKTOVIDEO + elem.url}
                                      disableRemotePlayback
                                      className='rounded-l-lg'
                                      key={index}
                                      about={elem.end!=0?elem.start.toString() + ',' + elem.end.toString():'0,'+elem.duration.toString() }
                                    >
                                      <source src={LINKTOVIDEO + elem.url} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    <div className='min-w-0 flex-1'>
                                      <div className='flex justify-between'>
                                        <p className='text-sm  text-gray-900'>
                                          {/* {elem.owner} */}
                                          prénom <br />
                                          nom  <br />
                                          {(elem.end!=0?elem.end - elem.start: elem.duration).toFixed(2)} <span className='text-xs'>
                                            secondes
                                          </span>
                                        </p>
                                        <div className='flex flex-col justify-between h-20'>
                                          <p className='pt  text-sm cursor-pointer' onClick={(e) => archiveVid(elem.url, true)}>
                                            <svg width="24px" height="24px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                          </p>
                                          <p className=' pb-1 text-sm cursor-pointer' onClick={(e) => { setOpenDialog(true); setSourceVid(elem.url); getDuration(elem.url) }}>
                                            <svg width="18px" height="18px" strokeWidth="1.8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M6.236 8a3 3 0 10-4.472-4 3 3 0 004.472 4zm0 0L16 16M17 12h1M22 12h1M6.236 16a3 3 0 11-4.472 4 3 3 0 014.472-4zm0 0L16 8" stroke="#000000" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                          </p>
                                        </div>
                                      </div>
                                      <p className='text-sm text-gray-500'>
                                        {/*  Durée: {elem.end - elem.start} secondes */}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className='flex flex-col justify-evenly opacity-30 hover:opacity-100 cursor-pointer active:cursor-grabbing'>
                                  {/* Your Drag Handle JSX here */}
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                        : null
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
      </>
    );
  }
  
 export const ArchiveVideoList: React.FC<ArchiveListProps> = ({ videos, handleOnDragEnd, archiveVid, LINKTOVIDEO }) => {
    return (
      <>
    
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='videos'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="flex flex-col">
                  {videos &&
                    videos.map((elem, index) => (
                      elem.isArchived ?
                        <Draggable key={elem.id} draggableId={elem.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="w-96 mx-4"
                            >
                              <div className='flex gap-2'>
                                <div className='bg-white rounded-lg my-2 w-80 mx-auto'>
                                  <div className='flex h-20 gap-4 justify-center'>
                                    <video
                                      id={elem.url}
                                      src={LINKTOVIDEO + elem.url}
                                      disableRemotePlayback
                                      className='rounded-l-lg'
                                      key={index}
                                      about={elem.start.toString() + ',' + elem.end.toString()}
                                    >
                                      <source src={LINKTOVIDEO + elem.url} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    <div className='min-w-0 flex-1'>
                                      <div className='flex justify-between'>
                                        <p className='text-sm font-semibold text-gray-900'>
                                          {/* {elem.owner} */}
                                          nom  <br />
                                          prénom
                                        </p>
                                        <div className='flex flex-col justify-between h-20'>
                                          <p className='pt  text-sm cursor-pointer' onClick={(e) => archiveVid(elem.url, false)}>
                                            --
                                          </p>
                                        </div>
                                      </div>
                                      <p className='text-sm text-gray-500'>
                                        {/*  Durée: {elem.end - elem.start} secondes */}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className='h-20 mt-2 flex flex-col justify-center'>
                                  {/* Your More Options JSX here */}
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                        : null
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
      </>
    );
  };