import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import CustomTab from './CustomTab';


interface EditingModalProps {
    open: boolean;
    closeDialog: () => void;
    videoSource: string;
    duration?: number;
    startEnd?: number[]
    handleEditingSave: (data: {
        stringSource: string;
        start: number;
        end: number;
    }) => void;
}

interface saveVideo {
    stringSource: string;
    start: number;
    end: number;
}


export default function EditingModal({ open, closeDialog, videoSource, duration, startEnd, handleEditingSave }: EditingModalProps) {
    //const [open, setOpen] = useState(true)

    const cancelButtonRef = useRef(null)

    const videoRef = useRef<HTMLVideoElement>(null)

    const [videoDuration, setVideoDuration] = useState(0)

    const [seekBar, setSeekBar] = useState(0)

    const [startToEnd, setStartToEnd] = useState([0, duration])

    const [playMode, setPlayMode] = useState(0)

    useEffect(() => {
        if (startEnd) {
            console.log(startEnd)
            setStartToEnd([startEnd[0], startEnd[1]])
        }
        setPlayMode(0)
        console.log(duration)

    }, [open])

    const handleVideoLoad = () => {
        if (videoRef.current) {
            // Video has loaded and is ready to play
            console.log('Video has loaded');
            durationReturn()

            // You can now perform any actions you want with the video, such as playing it:
        }
    };

    function handleSave() {
        if (startToEnd[0] && startToEnd[1]) {
            // warning --> || videoSource only for testing
            handleEditingSave({
                stringSource: videoSource.split('upload/')[1] || videoSource,
                start: startToEnd[0],
                end: startToEnd[1]
            })

        } else {
            console.log('not Saved')
        }
    }

     useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('loadeddata', handleVideoLoad);
            videoRef.current.load()

        }
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('loadeddata', handleVideoLoad);
            }
        };
    }, [open]); 

    function durationReturn() {
        console.log(videoRef.current?.duration)
        if (videoRef.current?.duration) {

            setVideoDuration(videoRef.current?.duration)
            return videoRef.current?.duration
        }

    }

    function sliderChange(values: number[], handle: number) {
        const totalDuration = durationReturn()
        if (videoRef.current && totalDuration) {
            if (handle === 0) {

                videoRef.current.currentTime = values[0]
                console.log(videoRef.current.currentTime)
            } else {
                console.log('handle 2')
                videoRef.current.currentTime = values[1]
            }
        }
    }

    function sliderEnd(values: number[], handle: number) {
        if (videoRef.current) {
            setStartToEnd([values[0], values[1]])
            console.log(videoRef.current.currentTime)
        }
    }




    function updateRange(e: any) {
        const totalDuration = durationReturn()
        if (videoRef.current && totalDuration) {
            videoRef.current.currentTime = ((e.target.value / 100) * totalDuration)
            setSeekBar(e.target.value)
        }
    }



    function playVideo() {
        videoRef.current?.pause()
        if (videoRef.current && startToEnd[0] && startToEnd[1]) {

            if (playMode === 1) {
                videoRef.current.currentTime = startToEnd[0]
            }
            else if (playMode === 0 || playMode === 2) {
                videoRef.current.currentTime = 0
            }
            else {
                videoRef.current.currentTime = startToEnd[1]
            }
        }
        videoRef.current?.play()

        updateSeeker()
    }

    function pauseVideo() {
        videoRef.current?.pause()
        if (videoRef.current)
            videoRef.current.currentTime = 0
    }

    function checkEnded() {
        const totalDuration = durationReturn()
        console.log(' check if ended')
        if (videoRef.current?.ended && totalDuration) {
            setSeekBar(totalDuration)
            console.log(' video Ended')
        }
    }

    // todo: playMode 2 and 3
    function updateSeeker() {
        const totalDuration = durationReturn()
        console.log(startToEnd)
        if (totalDuration && videoRef.current && startToEnd[0] && startToEnd[1]) {

            if (playMode === 1) {
                if (videoRef.current.currentTime >= startToEnd[1]) {
                    console.log('pause')
                    pauseVideo()
                    return
                }
                // console.log(videoRef.current?.currentTime)

            } else if (playMode === 2) {
                if (videoRef.current.currentTime >= startToEnd[0]) {
                    console.log('pause')
                    pauseVideo()
                    return
                }
            } else {
                // playMode 0 and 3
                if (videoRef.current.currentTime >= totalDuration) {
                    console.log('pause')
                    pauseVideo()
                    return
                }
            }
            const seekBar = videoRef.current?.currentTime / totalDuration * 100
            setSeekBar(seekBar)
        }

        checkEnded()
        videoRef.current?.requestVideoFrameCallback(updateSeeker)
    }






    return (



        <Transition.Root show={open} as={Fragment} >
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={closeDialog} >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div> */}

                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Editer la Vidéo
                                        </Dialog.Title>
                                        <div className="mt-2 flex flex-col gap-6">
                                            <video
                                                ref={videoRef}
                                                src={videoSource}
                                                disableRemotePlayback
                                                className='rounded-l-lg'
                                                onPlaying={(e) => { updateRange(e); console.log(videoRef.current?.currentTime) }}
                                            >
                                                <source src={videoSource} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>

                                            <div>
                                                <Nouislider tooltips={true} onEnd={sliderEnd} onUpdate={sliderChange} range={{ min: 0, max: duration ? duration : 100 }} start={[startToEnd[0] ? startToEnd[0] : 0, startToEnd[1] ? startToEnd[1] : 100]} connect behaviour='drag' />
                                            </div>
                                            <input type="range" min={0} max="100" value={seekBar} className="range range-xs" onChange={updateRange} />
                                            <div className='flex justify-around'>
                                                <div className='cursor-pointer' onClick={() => playVideo()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" strokeWidth="1.8" viewBox="0 0 24 24" color="#000000"><path stroke="#000000" strokeWidth="1.8" stroke-linecap="round" strokeLinejoin="round" d="M6.906 4.537A.6.6 0 0 0 6 5.053v13.894a.6.6 0 0 0 .906.516l11.723-6.947a.6.6 0 0 0 0-1.032L6.906 4.537Z"></path></svg>
                                                </div>
                                                <CustomTab tabs={['Tout', 'Sélection', 'Avant', 'Après']} status={(e) => setPlayMode(e)} />
                                            </div>
                                            <div>
                                                {/*   {videoDuration} */}
                                                {videoRef.current?.currentTime.toPrecision(2) || 0 + '/' + duration}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                        onClick={() => { handleSave(); closeDialog(); setStartToEnd([]); }}
                                    >
                                        Enregistrer
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={() => { closeDialog(); setStartToEnd([]); }}
                                        ref={cancelButtonRef}
                                    >
                                        Annuler
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
