import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface IVideoLink {
    value:string;
    duration:number;
}

const initialState : IVideoLink = {
    value: '',
    duration: 0
}

export const videoLinkSlice = createSlice({
    name: 'videoLinkios',
    initialState,
    reducers:{
        setCloudinaryLink : (state, action: PayloadAction<IVideoLink>) =>{
            state.value = action.payload.value
            state.duration = action.payload.duration
        }
    }

})

export const { setCloudinaryLink } = videoLinkSlice.actions

export default videoLinkSlice.reducer