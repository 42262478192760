import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { customFetch } from '../../api/customFetch';
import { error } from 'console';
import { useDispatch, useSelector } from 'react-redux';
import { addBobetteId, selectMaster, selectMasterId, setMaster } from '../../features/userInfo/masterSlice';
import { GoogleLogin } from '@react-oauth/google';
import { JwtPayload, jwtDecode } from "jwt-decode";
import { selectInvitationTitle, setCurrentBobetteId } from '../../features/userInfo/bobetteSlice';


interface P3_ConnexionProps {

}

interface LoginFormState {
  email: string;
  password: string;
  loggedIn: boolean;
}

interface UserData {
  email?: string;
  sub?: string;
  email_verified?: string;
  name?: string;
  picture?: string;
  given_name?: string;
  family_name?: string;
  locale?: string;
}


export default function P3_Connexion({ }: P3_ConnexionProps) {
  const [loginForm, setLoginForm] = useState<LoginFormState>({
    email: '',
    password: '',
    loggedIn: false,
  });

  const [projectName, setProjectName] = useState('')

  const [projectNameForm, setProjectNameForm] = useState(false)

  const [userData, setUserData] = useState<UserData>();


  const selectCurrentMaster = useSelector(selectMaster)

  const selectCurrentMasterId = useSelector(selectMasterId)

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLoginForm((prevLoginForm) => ({
      ...prevLoginForm,
      [name]: value,
    }));
  };

  const handleNameOfTheProject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.currentTarget.value)
  };

  const selectTitleOfInvitation = useSelector(selectInvitationTitle)

  const userAud = process.env.REACT_APP_USER_AUD;



  useEffect(() => {
    console.log(userData)
  }, [userData])



  // if is connected create Master and create one Bobette from master
  async function onSignIn(cred: any) {
    const data: any = jwtDecode<JwtPayload>(cred)
    Object.keys(data).forEach(key => {
      if (data[key as keyof UserData] && data[key]) {
        setUserData(prevState => ({
          ...prevState,
          [key]: data[key as keyof UserData]
        }));
      }
    });

    //    avant toute chose verifier le aud, qui l'id the l'app google sécurité
    if (data.aud != userAud) {
      console.log('Unauthorize')
      return
    }


    // check master in db?
    try {
      const checkMasterResponse = await customFetch('getMaster/', `?sub=${data.sub}&email=${data.email}`, '', {}, 'GET');



      if (checkMasterResponse && checkMasterResponse.id != '' && checkMasterResponse.name) {
        console.log(checkMasterResponse)
        console.log('Master Exist');
        const { id, email, name, familyName, bobettes } = checkMasterResponse;

        console.log('id:', id);
        console.log('email:', email);
        console.log('given_name:', name);
        console.log('family_name:', familyName);
        console.log('bobettes:', bobettes);

        console.log(bobettes)




        if (bobettes.length > 1) {
          console.log('Multiple Bobettes, navigate to bobette selection');
          dispatch(setMaster({ masterId: id, bobetteIds: bobettes.map((bob: any) => bob.id), email, name: name, secondName: familyName }));
          navigate('/bobette/projects');
        } else {
          console.log('Bobette exist, navigate to dashboard');
          dispatch(setMaster({ masterId: id, bobetteIds: [bobettes[0].id], email, name: name, secondName: familyName }));
          dispatch(setCurrentBobetteId(bobettes[0].id))
          navigate('/bobette/dashboard');
        }
      }


      else if (checkMasterResponse === 404) {

        console.log('its404')
        console.log('need to create a Master')
        console.log('Master does not exist, creating new master');
        const createMasterResponse = await customFetch('createMaster', `?name=${data.given_name}&familyName=${data.family_name}&email=${data.email}&sub=${data.sub}`, '', {}, 'POST');

        console.log(createMasterResponse);

        if (selectTitleOfInvitation !== '') {
          console.log('Bobette name from creation, connection ok');
          const createBobetteFromMasterResponse = await customFetch('createOneBobetteWithMaster', `?masterID=${createMasterResponse.id}&bobetteName=${selectTitleOfInvitation}`, '', {}, 'POST');
          dispatch(setMaster({ masterId: createMasterResponse.id, bobetteIds: [createBobetteFromMasterResponse.bobettes[0].id], email: data.email, name: data.given_name, secondName: data.family_name }));
          console.log(createBobetteFromMasterResponse);
          dispatch(setCurrentBobetteId(createBobetteFromMasterResponse.bobettes[0].id))

        } else {
          console.log('Modal: What is the title of your project?');
          dispatch(setMaster({ masterId: createMasterResponse.id, bobetteIds: [''], email: data.email, name: data.given_name, secondName: data.family_name }));
          setProjectNameForm(true);
        }
        // its not found create a master
      } else {
        console.log('Check Status')
        console.log(checkMasterResponse)
      }
    } catch (error: any) {

      console.error('An error occurred:', error);
      // Handle error gracefully, show error message or retry logic
    }

  }

  const createBobetteProjectName = (project: string) => {
    const createBobetteFromMaster = customFetch('createOneBobetteWithMaster', `?masterID=${selectCurrentMasterId}&bobetteName=${project}`, '', {}, 'POST')
    createBobetteFromMaster.then((newrep) => {
      console.log(newrep)

      dispatch(addBobetteId(newrep.id))
      dispatch(setCurrentBobetteId(newrep.id))
    }).finally(() => {
      console.log('dispatched Master')
      console.log(selectCurrentMaster)
      navigate('/bobette/dashboard')
    }
    )
  }




  return (
    <>

      <div className="min-h-screen  flex flex-col justify-center sm:py-12">
        <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
          <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
            <div className="pt-1.5">
              <h1 className="text-3xl font-bold text-gray-900">Connexion </h1>

              <GoogleLogin
                onSuccess={credentialResponse => {
                  console.log(credentialResponse.credential);
                  onSignIn(credentialResponse.credential)
                }}

                onError={() => {
                  console.log('Login Failed');
                }}
              />

              {/* For Connexion  */}
              { /* <p className="text-sm font-medium text-gray-500">
                Renseignez vos identifiants
              </p>
            </div>
            <div className="px-5 py-7">
              <label className="font-semibold text-sm text-gray-600 pb-1 block">E-mail</label>
              <input type="email" autoComplete='off' name="email" className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" value={loginForm.email}
                onChange={handleInputChange} />
              <label className="font-semibold text-sm text-gray-600 pb-1 block">Mot de passe</label>
              <input type="password" name="password" className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" value={loginForm.password}
                onChange={handleInputChange} />
              <button type="button" className="transition duration-200 bg-indigo-600 hover:bg-indigo-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
                onClick={handleLogin}>
                <span className="inline-block mr-2">Se connecter</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4 inline-block">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </button> */}
            </div>


          </div>
        </div>
        {projectNameForm &&
          <>
            <div className='my-4 w-64 flex flex-col justify-center  mx-auto' >
              <label className="font-semibold text-sm text-gray-600 pb-1 block">Quel est le titre de votre projet ?</label>
              <input type="text" autoComplete='off' name="email" className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm text-slate-800 w-full" value={projectName}
                onChange={handleNameOfTheProject} />
              <button type="button" className="transition duration-200 bg-indigo-600 hover:bg-indigo-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
                onClick={() => createBobetteProjectName(projectName)}
                style={projectName.length < 3 ? { backgroundColor: 'grey', opacity: 0.6 } : {}}
                disabled={projectName.length < 3}>
                Valider
              </button>
            </div>
          </>}

      </div>

    </>
  )
}

