import axios, { AxiosRequestConfig } from 'axios';




export const CURRENTLINK = 'https://bobette2022-002-site1.ftempurl.com/'

// export const CURRENTLINK = 'https://bobette.art/'
// export const CURRENTLINK ='https://localhost:5001/'

type apiRoutes = 
'getMasters'|
'getMaster/'|
'getAllBobettesFromMaster/'|
'getAllVmakers/'|
'getOneVmaker/'|
'getBobette/'|
'getAllVlinks'|
'getAllUrls'|
'createMaster'|
'createOneBobetteWithMaster'|
'createVmaker';


export const customFetch = async (
  apiRoute: apiRoutes,
  routeAddition?: string,
  id?: string,
  body?: any,
  typeOfCRUD?: string
): Promise<any> => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      // You can add any other headers you need here
    };

    let url = CURRENTLINK + apiRoute;
    if (routeAddition) url += routeAddition;
    if (id) url += id;

    const config: AxiosRequestConfig = {
      method: typeOfCRUD || 'GET', // Default to 'GET' if no typeOfCRUD is provided
      headers,
    };

    if (typeOfCRUD === 'POST' || typeOfCRUD === 'PUT' || typeOfCRUD === 'PATCH') {
      config.data = body;
    }

    const response = await axios(url, config);
    const data = response.data;

    if (!response.status.toString().startsWith('2')) {
      // Handle error responses here if needed
      console.error('Something went wrong');
      // throw new Error(data.message || 'Request failed');
    }



 
    return data;
  } catch (error:any) {
    if(error.response.status===404){
      
      return error.response.status

    }
   

    // Handle exceptions (e.g., network errors) here
    throw error;
  }
};
  