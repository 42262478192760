import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface IBlob {
    value: Array<Blob>
}

const initialState : IBlob = {
    value: []
}

export const blobSlice = createSlice({
    name: 'currentBlob',
    initialState,
    reducers:{
        setBlobArray : (state, action: PayloadAction<IBlob>) =>{
            state.value = action.payload.value
        }
    }

})

export const { setBlobArray } = blobSlice.actions

export default blobSlice.reducer