import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentVmaker} from '../features/userInfo/vmakerSlice';


/* 
interface stepsProps {

} */


export default function Steps() {
  const navigate = useNavigate()
  const [bobetteName, setBobetteName] = useState('')
  const selectVmaker = useSelector(selectCurrentVmaker)
  const currentName = "pour la fête d'anniversaire de Nina :)"

  const requestOptions: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Origin': 'http://localhost:3000',

    },

};

  useEffect(() => {
    console.log(selectVmaker.bobetteId)
    // no need to refetch, will be stored on refactoring
    fetch(`https://bobette2022-002-site1.ftempurl.com/getBobette/${selectVmaker.bobetteId}`, requestOptions)
        .then(response => response.json())
        .then(function (data) {
            console.log(data);
            setBobetteName(data.name)
        })
}, [])

  return (
    <>
      <div className='h-screen flex flex-col gap-6'>
       <div className='flex justify-center'>
        <h1 className='self-center z-10 text-secondary-content pt-1'>Les étapes:</h1>
       </div>
        <div className=' flex justify-center h-2/5 '>
          <ul className="steps steps-vertical w-96 px-auto  card bg-cyan-200 pb-10 shadow-xl">
            <li className="step step">Autorise l'utilisation de la camera</li>
            <li className="step step">Enregistre ton témoignage </li>
            <li className="step step ">Ta vidéo est prête ?  Clique sur <br /> “Envoyer”</li>
            <li className="step step">Si tu n'es pas satisfait(e), reviens en arrière <br /> pour recommencer</li>

            {/*   <li className="step">
          Après récupération de tous les témoignages,
          la vidéo est automatiquement générée</li>
        <li className="step">
          <div className='flex flex-col pt-6'>
            La surprise est envoyée à Camille
            <img className='h-10 w-10 mx-auto' src='./../party.png'></img>
          </div>
        </li> */}
          </ul>
        </div>
        <div className=" card bg-rose-100 shadow-xl badge badge-success gap-2 h-48 w-80 self-center text-center text-lg p-3 flex flex-col">
          <div className='flex gap-2'>
            Après récupération de tous les extraits,
            une vidéo est automatiquement générée et

            la surprise est prête à être diffusée {currentName} {/* {bobetteName}  */}!
            <img className='h-10 w-10 mx-auto self-center' src='./../party.png'></img>
          </div>
        </div>
        <button className="w-48 mt-4 self-center bg-colorB1 text-white drop-shadow-lg p-3 rounded-lg text-slate-100 uppercase flex justify-around" onClick={() => navigate('/startRecording')}>
          Suivant
          {/* <img className='' src='./../video-camera.svg'></img> */}
        </button>
      </div>
    </>

  )
}
