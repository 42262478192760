import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { customFetch } from '../../api/customFetch'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentVmaker } from '../../features/userInfo/vmakerSlice'
import { MASTEREXAMPLE } from '../../api/falsiLogInfo'
import { selectMaster, selectMasterId } from '../../features/userInfo/masterSlice'
import { setCurrentBobetteId } from '../../features/userInfo/bobetteSlice'

interface P7_MyBobettesProps {

}

interface project {
  id: string;
  master: string;
  masters: [];
  name: string;
  vmakers: [];
}

export default function P7_MyBobettes({ }: P7_MyBobettesProps) {
  // fetch my bobettes

  const selectCurrentMasterId = useSelector(selectMasterId)
  const [projects, setProjects] = useState<project[]>()


  useEffect(() => {
    const allBobettes = customFetch('getAllBobettesFromMaster/', '', selectCurrentMasterId, 'GET')
    allBobettes.then(data => {
      console.log(data);
      setProjects(data)
    }
    )
  }, [])


  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleNavigate = (projectId: string) => {
    const bobetteID = projectId
    dispatch(setCurrentBobetteId(bobetteID))
    navigate('/bobette/dashboard')
  }


  return (
    <>
      <div className='flex justify-between'>


        <h1 className="text-3xl font-bold text-gray-900"> Mes Bobettes </h1>


        <button
          type="button"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => navigate('/bobette/create')}
        >
          Nouveau Projet
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
        </button>

      </div>
      <div className='py-4'>
        <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">

          {projects ?
            projects.map((project, index) => (
              <li key={project.name} className="col-span-1 flex rounded-md shadow-sm" onClick={() => handleNavigate(project.id)}>
                <img className='h-24' src={`https://picsum.photos/300/300/?random=${index}`} alt="" />
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 truncate px-4 py-2 text-sm" >
                    {/*  <a href={project.href} className="font-medium text-gray-900 hover:text-gray-600">
                    {project.name}
                  </a> */}
                    <p className="cursor-pointer font-medium text-gray-900 hover:text-gray-600">
                      {project.name}
                    </p>
                    <p className="text-gray-500"> Deadline</p>
                  </div>
                  <div className="flex-shrink-0 pr-2">
                    <button
                      type="button"
                      className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </li>
            ))
            :
            undefined
          }
        </ul>
      </div>

    </>
  )
}
