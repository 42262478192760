import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentVmaker } from '../features/userInfo/vmakerSlice';

interface BobetteStatusProps { }

const requestOptions: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        Origin: 'http://localhost:3000',
    },
};

interface Vmaker {
    bobetteID: string;
    email: string;
    id: string;
    masterID: string;
    name: string;
    participants: number;
    secondName: string;
    textIndex: number;
    vlinks: string[];
}

interface Vlink {
    end: number;
    id: string;
    start: number;
    url: string;
    vmakerId: string;
}

interface VlinkElem {
    id: string;
    vmakerId: string;
}

interface VmakerElem {
    id: string;
    name: string;
}

export default function BobetteStatus({ }: BobetteStatusProps) {
    // const selectVmaker = useSelector(selectCurrentVmaker)
    const BobetteHeleneEtMartin = 'FBA83867-8B32-4C7B-99DE-91F626287A43';
    const [vLinks, setVLinks] = useState<Vlink[] | undefined>();
    const [allVmakers, setAllVmakers] = useState<Vmaker[] | undefined>();

    function fetchVmakersAndVlinks() {
        fetch(
            `https://bobette2022-002-site1.ftempurl.com/getAllVLinks?bobetteId=${BobetteHeleneEtMartin}`,
            requestOptions
        )
            .then((response) => response.json())
            .then(function (data) {
                console.log(data);
                setVLinks(data);
                // firstTry
            });

        fetch(
            `https://bobette2022-002-site1.ftempurl.com/getAllVmakers/${BobetteHeleneEtMartin}`,
            requestOptions
        )
            .then((response) => response.json())
            .then(function (data) {
                console.log(data);
                setAllVmakers(data);
                // firstTry
            });
    }

    useEffect(() => {
        fetchVmakersAndVlinks();
        // Added missing closing parenthesis for useEffect
    }, []);

    const [doneList, setDoneList] = useState<Vmaker[]>([]);
    const [undoneList, setUndoneList] = useState<Vmaker[]>([]);

    function compare() {
        // Compare the two arrays and update the lists accordingly
        console.log('compare');
        console.log(allVmakers);
        if (allVmakers) {
            console.log('allvmakerVlink');
            allVmakers.forEach((item: Vmaker) => {
                const foundItem = vLinks?.find((vlink: Vlink) => vlink.vmakerId === item.id);
                console.log(foundItem);
                console.log('***********');
                if (foundItem) {
                    setDoneList((prevList) => [...prevList, item]);
                } else {
                   if (item.secondName)
                    setUndoneList((prevList) => [...prevList, item]);
                }
            });
        }
    }

    useEffect(() => {
        compare();
        // Moved the compare function to a separate useEffect
    }, [allVmakers, vLinks]);

    return (
        <div>
            <h2>Done:</h2>
            <div>{allVmakers ? allVmakers[0].name : ''}</div>
            <ul className='flex flex-col gap-2'>
                {doneList.length > 0 ? (
                    doneList.map((item: Vmaker) => <li className='flex flex-col' key={item.id}>
                        <div>
                            {item.name} {item.secondName}:
                        </div>
                        <div>

                            {item.id}
                        </div>
                    </li>

                    )
                ) : (
                    <li>No items done.</li>
                )}
            </ul>

            <h2>Undone:</h2>
            <ul className='flex flex-col gap-2'>
                {undoneList.length > 0 ? (
                    undoneList.map((item: Vmaker) => <li className='flex flex-col' key={item.id}>
                    <div>
                        {item.name} {item.secondName}:
                    </div>
                    <div>

                        {item.id}
                    </div>
                </li>)
                ) : (
                    <li>No items undone.</li>
                )}
            </ul>
        </div>
    );
}
