import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';

export interface Imaster{
    masterId: string;
    bobetteIds: string[];
    email: string;
    name: string;
    secondName: string;

}

const initialState : Imaster ={
    masterId: '',
    bobetteIds:[''],
    email: '',
    name: '',
    secondName: '',
}

export const masterSlice = createSlice({
    name: 'currentMaster',
    initialState,
    reducers: {
      // Action to set master data
      setMaster: (state, action: PayloadAction<Imaster>) => {
        return action.payload;
      },
  
      // Action to update master data by providing a partial update
      updateMaster: (state, action: PayloadAction<Partial<Imaster>>) => {
        return { ...state, ...action.payload };
      },
  
      // Action to add a bobetteId to the master's bobetteIds array
      addBobetteId: (state, action: PayloadAction<string>) => {
        if(state.bobetteIds.length===1 && state.bobetteIds[0]===''){
          state.bobetteIds = [action.payload]
        }else{
          state.bobetteIds.push(action.payload);
        }
      },
  
      // Action to remove a bobetteId from the master's bobetteIds array
      removeBobetteId: (state, action: PayloadAction<string>) => {
        state.bobetteIds = state.bobetteIds.filter((id) => id !== action.payload);
      },
  
      // Action to reset the master state to initial state
      resetMaster: (state) => {
        return initialState;
      },
    },
  });
  
  export const { setMaster, updateMaster, addBobetteId, removeBobetteId, resetMaster } = masterSlice.actions;
  
  export const selectMaster = (state:RootState) => state.currentMaster
  export const selectMasterId = (state:RootState) => state.currentMaster.masterId
  export const selectBobettes = (state:RootState) => state.currentMaster.bobetteIds


  export default masterSlice.reducer;