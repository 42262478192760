import React from 'react'
import QRCode from "react-qr-code";
import { CURRENTLINK } from '../api/customFetch';
import { useSelector } from 'react-redux';
import { selectBobetteId } from '../features/userInfo/bobetteSlice';


interface InvitationProps {

}

export default function Invitation({ }: InvitationProps) {
  const bobetteId = useSelector(selectBobetteId)
  const value = CURRENTLINK +  bobetteId
  return (
    <div className='flex flex-col self-center mt-10 gap-4 text-center'>
      <p>
        Voici le qr code vers ton invitation:
      </p>
      <div className='flex self-center '>
        <QRCode
          className='mx-auto'
          size={256}
          style={{ height: "auto", maxWidth: "40%", width: "40%" }}
          value={value}
          viewBox={`0 0 256 256`}
        />
      </div>
      {value}
    </div>
  )

}
