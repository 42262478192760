import React, { useState, useEffect, useCallback, memo } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import VideoPlayer from '../components/VideoPlayer'
import { useDispatch } from 'react-redux';
import { selectVlinks, setVlinks } from '../features/userInfo/bobetteSlice';
import { useSelector } from 'react-redux';
import EditingModal from '../components/EditingModal';
import VideoPlayer2 from '../components/VideoPlayer2';
import CustomTab from '../components/CustomTab';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ArchiveVideoList, VideoList } from '../components/VideoLists/VideoList';
import { selectAllVlinks } from '../features/userInfo/vlinksSlice';

interface EditingBobetteProps {

}

interface EditVideo {
  id: string;
  url: string;
  start: number;
  end: number;
  duration: number; 
  uploadOrder: number;
  order: number;
  vmakerId: string;
  isArchived: boolean;
}


const  EditingBobette =  ()=> {

  const LINKTOVIDEO = 'https://res.cloudinary.com/dxq4veqsa/video/upload/'

  // const LINKTOVIDEO = ''

  const dispatch = useDispatch()

  const [openDialog, setOpenDialog] = useState(false)
  const [sourceVid, setSourceVid] = useState('')
  const [currentDuration, setCurrentDuration] = useState(0)
  const [startEnd, setStartEnd] = useState([0, 0])
  const [videoTab, setVideoTab] = useState(0)

  const requestOptions: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*',
      'Origin': 'http://localhost:3000',

    },

  };

  const monExample =
    [

      {
        id: "5f5f23c2-d4db-42a6-bd95-58e01c80783f",
        url: "",
        start: 0,
        end: 0,
        duration: 0,
        uploadOrder: 0,
        order: 0,
        vmakerId: "81f7c4df-c1aa-46ea-ae40-1e69bf39a40b"
      },
      {
        id: "22271175-2a2e-4b67-9229-cee9f3dd673e",
        url: "",
        start: 0,
        end: 0,
        duration: 0,
        uploadOrder: 0,
        order: 0,
        vmakerId: "49e0bf74-7601-47f1-94b3-435d95a15fe8"
      },

    ]

  const videos = [
    'https://player.vimeo.com/external/371433846.sd.mp4?s=236da2f3c0fd273d2c6d9a064f3ae35579b2bbdf&profile_id=164&oauth2_token_id=57447761',
    'https://player.vimeo.com/external/454667698.sd.mp4?s=a8d5cebb7aee26128beb954ef8f53827c5adde9b&profile_id=164&oauth2_token_id=57447761',
    'https://player.vimeo.com/external/473178018.sd.mp4?s=f37cb1af5b53d74bf9d2f6e9525f15646e4cba60&profile_id=164&oauth2_token_id=57447761',
    'https://player.vimeo.com/external/372085467.sd.mp4?s=7a8a61433e672c0c9038510eea7ec9e69f53194d&profile_id=164&oauth2_token_id=57447761',
    'https://player.vimeo.com/external/448550889.sd.mp4?s=1ebef2c77a24e64d5009a4562c6533afd27e253d&profile_id=165&oauth2_token_id=57447761'
  ];




  const modifiedVideos = videos.map((video, index) => {
    return {
      id: `video_${index + 1}`,
      url: video,
      start: 5,
      end: 6,
      duration: 0,
      vmakerId: `vmaker_${index + 1}`,
      order: index,
      uploadOrder: index,
      isArchived: false
    };
  });
  
  const selectCurrentVlinks = useSelector(selectAllVlinks)





  const [myVideos, setMyVideos] = useState<EditVideo[]>(
    
[]
  )
  
  useEffect(()=>{
    
    if(selectCurrentVlinks){
     console.log(selectCurrentVlinks)
      setMyVideos( selectCurrentVlinks)
    }

   /*  if(myVideos.length===0){
      setMyVideos(modifiedVideos)
    } */

  },[selectCurrentVlinks])


  useEffect(()=>{
    // select Vlinks
  
  },[])


  function makeOrder(data: EditVideo[]) {
    console.log(data)
    const newArray = []
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (element.order === 333 || element.order === 0) {

        // or upload Order    
        element.order = i
      }
      newArray.push(element)

    }
    newArray.sort((a, b) => a.order - b.order);
    console.log(newArray)
    setMyVideos(newArray)
    // setVideos
    // update elems

  }


  // fetching current Bobette
  useEffect(() => {

    // change bobette ID by a short uuid (shared to all vmakers) : https://www.npmjs.com/package/short-uuid
    // fetching the with ID of Bobette
    /*
        fetch('https://bobette2022-002-site1.ftempurl.com/getAllVLinks?bobetteId=FBA83867-8B32-4C7B-99DE-91F626287A43', requestOptions)
          .then(response => response.json())
          .then(function (data: EditVideo[]) {
            console.log(data)
            setMyVideos(data)
            dispatch(setVlinks(data.map(elem => elem.url)))
            // firstTry
            makeOrder(data)
          })
    
    */

  }, [])


  // https://res.cloudinary.com/dxq4veqsa/video/upload/v1684183408/znflikzg1kbiwpmzilrv.mov
  // https://res.cloudinary.com/dxq4veqsa/video/upload/
  // c_fill,h_720,w_1280/
  // fl_splice,l_video:
  // v1679833098:ik5uqh7zxmpozhemq97z/
  // c_fill,h_720,w_1280/
  // fl_splice,l_video:v1679835659:okyksqu7d6qxgjs3v7h5/
  // c_fill,h_720,w_1280/fl_layer_apply/
  // v1679832370/ybgjuh209coi7gh8u9so.mp4

  // https://res.cloudinary.com/dxq4veqsa/video/upload/
  // c_fill,h_720,w_1280/
  // fl_splice,l_video:
  // Array[1].vlink + /
  // c_fill,h_720,w_1280/
  // fl_splice,l_video:
  //  Array[2].vlink + /
  // c_fill,h_720,w_1280/fl_layer_apply/
  // v1679832370/ybgjuh209coi7gh8u9so.mp4

  const makeAndDownloadThevideo = (vidArrayFromState: any) => {
    const stringArray = []
    for (let i = 1; i < vidArrayFromState.length; i++) {
      stringArray.push('c_fill,h_1080,w_1920/fl_splice,l_video:' + vidArrayFromState[i].url.replace('/', ':') + '/')
    }
    const stringResult = 'https://res.cloudinary.com/dxq4veqsa/video/upload/' + stringArray.join('') + 'c_fill,h_1080,w_1920/fl_layer_apply/' + vidArrayFromState[0].url + '.mp4'
    console.log(stringResult)
  }



  useEffect(() => {
   
    const vid = document.getElementById('v1692543489/icram92jomdyoqinp8bb') as HTMLVideoElement
    if (vid) {

      console.log(vid.duration!)
    }
  }, [myVideos])




  // https://res.cloudinary.com/dxq4veqsa/video/upload/c_fill,h_720,w_1280/fl_splice,l_video:v1679756803:dwq2lrjfbyblfplffkvt/c_fill,h_720,w_1280/fl_splice,l_video:v1679837208:l2capadchpl3vttbbuq5/c_fill,h_720,w_1280/fl_splice,l_video:v1684182616:t4rbd484ziccyhveuh31/c_fill,h_720,w_1280/fl_splice,l_video:v1684182616:t4rbd484ziccyhveuh31/c_fill,h_720,w_1280/fl_splice,l_video:v1684182616:t4rbd484ziccyhveuh31/c_fill,h_720,w_1280/fl_splice,l_video:v1684182616:t4rbd484ziccyhveuh31/c_fill,h_720,w_1280/fl_splice,l_video:v1684182616:t4rbd484ziccyhveuh31/c_fill,h_720,w_1280/fl_splice,l_video:v1679832370:ybgjuh209coi7gh8u9so/c_fill,h_720,w_1280/fl_splice,l_video:v1679834212:ec25rcvfoohzslwgitfj/c_fill,h_720,w_1280/fl_splice,l_video:v1679834177:irsavccsjnuregek0wh5/c_fill,h_720,w_1280/fl_splice,l_video:v1679833098:ik5uqh7zxmpozhemq97z/c_fill,h_720,w_1280/fl_splice,l_video:v1679835659:okyksqu7d6qxgjs3v7h5/c_fill,h_720,w_1280/fl_splice,l_video:v1679828374:lsmazj1js6aiazwtxykj/v1684181973/n2ieev9asttepwtglgyf.mp4

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return; // Return if the item was not dropped in a droppable area
    console.log(myVideos)
    const items = [...myVideos]
    console.log(items)
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const updatedItems = items.map((item, index) => ({
      ...item,
      order: index
    }));

    console.log(items)
    // Update the state with the reordered items
    console.log(updatedItems)

    setMyVideos(updatedItems);
    console.log(items)
  };

  const handleUpOrder = (index: number) => {
    if (index === 0) return
    const items = Array.from(myVideos);
    const [reorderedItem] = items.splice(index, 1)
    items.splice(index - 1, 0, reorderedItem);
    items.forEach((elem, index) => elem.order = index)
    setMyVideos(items);
  }

  const handleDownOrder = (index: number) => {
    if (index === myVideos.length - 1) return
    const items = Array.from(myVideos);
    const [reorderedItem] = items.splice(index, 1)
    items.splice(index + 1, 0, reorderedItem);
    items.forEach((elem, index) => elem.order = index)
    setMyVideos(items);
  }

  function getDuration(sourceVid: string) {
    console.log(sourceVid)
    const vid = document.getElementById(sourceVid) as HTMLVideoElement
    console.log(vid.getAttribute('about'))
    if (vid.getAttribute('about') !== null) {
      const startEndArray = [parseFloat(vid.getAttribute('about')!.split(',')[0]), parseFloat(vid.getAttribute('about')!.split(',')[1])]
      setStartEnd(startEndArray)
    }

    setCurrentDuration(vid.duration)
    // return vid.duration
  }

  function saveSpecificEdit(data: {
    stringSource: string;
    start: number;
    end: number;
  }) {
    const updatedVideos = myVideos.map((elem) => {
      if (elem.url === data.stringSource) {
        return {
          ...elem,
          start: data.start,
          end: data.end,
        };
      }
      return elem;
    });

    console.log(updatedVideos)


    setMyVideos(updatedVideos);

  }

  useEffect(() => {
    console.log("myVideos has changed:", myVideos);
    
  }, [myVideos]);


  const archiveVid = (id: string, toArchived: boolean) => {
    console.log(id)
    if (id === '') return
    const updatedVideos = [...myVideos];

    const foundVideoIndex = updatedVideos.findIndex((v) => v.url === id);

    if (foundVideoIndex !== -1) {
      console.log(foundVideoIndex)
      // Create a copy of the found video object
      const updatedVideo = { ...updatedVideos[foundVideoIndex] };
      updatedVideo.isArchived = toArchived;

      // Update the array with the new video object
      updatedVideos[foundVideoIndex] = updatedVideo;
      setMyVideos(updatedVideos);
    }

  }

  const totalDuration = (arr: number[]) => {
    let sum = 0
    arr.forEach(ele =>
      sum += ele
    )
    return sum
  }

  return (
    <>
      <div className='flex justify-around mx-auto w-full'>

        <div className='h-full w-auto'>
          <div className='flex w-48 mx-auto m-4'>

            <p className='self-center '>
              Vidéos des participants
            </p>
          </div>



          <CustomTab tabs={['Mon Montage', 'Mes Archives']} status={(e) => setVideoTab(e)} />
          {myVideos && <div className='flex '>
            <div className=' overflow-y-auto mx-4 '>
              {
                videoTab === 0 &&
               <VideoList videos={myVideos}
                  handleOnDragEnd={handleOnDragEnd}
                  archiveVid={archiveVid}
                  LINKTOVIDEO={LINKTOVIDEO}
                  getDuration={getDuration}
                  setSourceVid={setSourceVid}
                  setOpenDialog={setOpenDialog} />
              }
              {
                videoTab === 1 &&
                (<ArchiveVideoList videos={myVideos}
                  handleOnDragEnd={handleOnDragEnd}
                  archiveVid={archiveVid}
                  LINKTOVIDEO={LINKTOVIDEO} />
                )
              }
            </div>
            <div>
            <div className='flex gap-10 w-full mx-auto m-4'>
              <p className='self-center '>
                Résultat
              </p>
              <p>
                Temps total: {totalDuration(myVideos.filter(v => v.isArchived === false).map(el => el.end - el.start))} secondes
              </p>
            </div>
            <VideoPlayer vlinks={videoTab === 0 ? myVideos.filter(v => v.isArchived === false) : myVideos.filter(v => v.isArchived === true)} />
            </div>
          </div>}
        </div>

        <EditingModal
          open={openDialog}
          closeDialog={() => {setOpenDialog(false); makeAndDownloadThevideo(myVideos)} }
          videoSource={LINKTOVIDEO + sourceVid}
          startEnd={startEnd}
          duration={currentDuration}
          handleEditingSave={saveSpecificEdit}
        />


        {/* <button onClick={() => makeAndDownloadThevideo(myVideos)}> Télécharger</button> */}

      </div>

    </>
  )
}

export default EditingBobette
