import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';


export interface IVmaker {
    vmakerId:string;
    bobetteId:string;
    masterId:string;
    email?:string;
    participants?: number;
    secondName?: string;
    textIndex?:number; 
    currentGroup?: number;
}

export interface IObjectVmaker {
    [value:string] : IVmaker
}

const initialState : IObjectVmaker = {
   value: { vmakerId:'',
    bobetteId:'',
    masterId:'',
    email:'',
    participants: 0,
    secondName: '',
    textIndex:333, 
    currentGroup: 1
}
}

export const vmakerSlice = createSlice({
    name: 'currentVmaker',
    initialState,
    reducers:{
        setVmakerInfo : (state, action: PayloadAction<IObjectVmaker>) =>{
            state.value = action.payload.value
            console.log(action.payload.value)
        },
        setIncremCurrentGroup :(state, action: PayloadAction<number>) =>{
            if(state.value.currentGroup)
            state.value.currentGroup += 1
        }
    }

})

export const selectCurrentVmaker = (state:RootState) => state.currentVmaker.value





export const { setVmakerInfo } = vmakerSlice.actions

export default vmakerSlice.reducer