import { ArrowUpIcon, CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon } from '@heroicons/react/20/solid'
import ArrowDownIcon from '@heroicons/react/20/solid/ArrowDownIcon'
import React, { useEffect, useState } from 'react'
import EditingBobette from '../EditingBobette'
import { selectBobetteId, selectInvitationDescription, selectInvitationTitle, selectVlinks } from '../../features/userInfo/bobetteSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Invitation from '../Invitation'
import { selectBobettes, selectMaster, selectMasterId } from '../../features/userInfo/masterSlice'
import { customFetch } from '../../api/customFetch'
import MemoizedTabs from '../../components/DashboardTabs/MemoizedTabs'
import { useDispatch } from 'react-redux'
import { setVlinks } from '../../features/userInfo/vlinksSlice'

interface P6_DashboardProps {

}

type tabs = 'editing' | 'invite' | 'finalised';


export default function P7_Dashboard({ }: P6_DashboardProps) {

    /*  const selectMyVlinks = useSelector(selectVlinks)
  
      const selectMyInvitationDescription = useSelector(selectInvitationDescription)
  
      const selectMyInvitationTitle = useSelector(selectInvitationTitle)
  
      const navigate = useNavigate() */

    const [openDialog, setOpenDialog] = useState(false)

    const [actualTab, setActualTab] = useState<tabs>('editing')

    const selectedMaster = useSelector(selectMaster)
    const selectedBobetteId = useSelector(selectBobetteId)

    const [bobetteName, setBobetteName] = useState('')

    const dispatch = useDispatch()

    useEffect(() => {
        const getBobette = customFetch('getBobette/', `${selectedBobetteId}`, '', {}, 'GET')
        getBobette.then(rep => { console.log(rep); setBobetteName(rep.name) })

        console.log(selectedBobetteId)

        const getAllVlinks = customFetch('getAllVlinks', `?bobetteId=${selectedBobetteId}`, '', {}, 'GET')
        getAllVlinks.then(rep => {  dispatch(setVlinks(rep));console.log(rep); })

        // dispatch Vlinks
       



        console.log(selectedMaster)
        console.log(selectedBobetteId)

        // bb82080a-8eeb-4005-b1a4-0e85e4fcb78c  ef2a76ea-953d-4cb6-a289-a25c2032725e
    }, [])



    return (
        <>

            <div className='grid grid-cols-6 w-full   px-6  py-24 sm:pt-3 lg:px-8' style={{ height: window.innerHeight * 0.85 }}>


                <div className="col-span-6" >
                    <div className="mx-auto max-w-2xl text-center ">
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900  sm:text-2xl ">Bonjour {selectedMaster.name}</h2>
                        {bobetteName && (<p className="mt-4 text-md leading-8 text-gray-600">
                            Bienvenue dans ton espace de création Bobette de {bobetteName} <br />
                            Ici tu peux partager et éditer tes vidéos
                        </p>)}
                    </div>
                    <div>

                    </div>
                    {/* Video Editing */}
                    <div className='flex flex-col  w-full'>

                        {/*    {actualTab === 'editing' && (
                            
                          <EditingBobette />
                        )
                        }
                        {actualTab === 'invite' && (
                            <Invitation />
                        )
                        }
                        */}

                        <MemoizedTabs actualTab={actualTab}/>



                    </div>
                    <div className='flex justify-center'>
                        <div className='flex w-80 justify-around my-5 h-20 fixed bottom-0'>
                            <button className={actualTab === 'editing' ? 'text-gray-900 border-b-2 border-slate-600' : 'text-gray-900 opacity-40'} onClick={() => setActualTab('editing')}>
                                <img className={'h-8 w-8 mx-auto '} src="/icon/editing/edit-pencil.svg" alt="" />
                                Editer
                            </button>
                            <button className={actualTab === 'invite' ? 'text-gray-900 border-b-2 border-slate-600' : 'text-gray-900 opacity-40'} onClick={() => setActualTab('invite')}>
                                <img className='h-8 w-8 mx-auto' src="/icon/invitation/mail.svg" alt="" />
                                Inviter
                            </button>
                            <button className={actualTab === 'finalised' ? 'text-gray-900 border-b-2 border-slate-600' : 'text-gray-900 opacity-40'} onClick={() => setActualTab('finalised')}>
                                <img className='h-8 w-8 mx-auto' src="/icon/finalisation/rocket.svg" alt="" />
                                Finaliser
                            </button>
                        </div>
                    </div>


                </div>

            </div>
            <div>

                <Dialog
                    open={openDialog}
                    onClose={(() => setOpenDialog(false))}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Voici le lien à partager"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            https://bobette2022-002-site1.ftempurl.com/index.html/#/7BCC73EE-D99D-4AD0-8354-35B68A12C673
                            <Button>Copier le lien</Button>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)}>Annuler</Button>
                        <Button onClick={() => setOpenDialog(false)} autoFocus>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}
