import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';


// Define the interface for Vlink
interface Vlink {
  id: string;
  url: string;
  start: number;
  end: number;
  duration: number; 
  uploadOrder: number;
  order: number;
  vmakerId: string;
  isArchived: boolean;
}

// Define the initial state
interface VlinkState {
  vlinks: Vlink[];
}

const initialState: VlinkState = {
  vlinks: [],
};

// Create a slice
const vlinkSlice = createSlice({
  name: 'vlinks',
  initialState,
  reducers: {
    setVlinks(state, action: PayloadAction<Vlink[]>) {
      state.vlinks = action.payload;
    },
   /*  addVlink(state, action: PayloadAction<Vlink>) {
      state.vlinks.push(action.payload);
    },
    removeVlink(state, action: PayloadAction<string>) {
      state.vlinks = state.vlinks.filter(vlink => vlink.id !== action.payload);
    },
    updateVlink(state, action: PayloadAction<Vlink>) {
      const index = state.vlinks.findIndex(vlink => vlink.id === action.payload.id);
      if (index !== -1) {
        state.vlinks[index] = action.payload;
      }
    },
    archiveVlink(state, action: PayloadAction<string>) {
      const index = state.vlinks.findIndex(vlink => vlink.id === action.payload);
      if (index !== -1) {
        state.vlinks[index].isArchived = true;
    },
} */
    // Add other actions as needed
  },
});

// Export actions
export const { setVlinks, /* addVlink, removeVlink, updateVlink, archiveVlink */ } = vlinkSlice.actions;

// Selectors
export const selectAllVlinks = (state: RootState) => state.currentVLinks.vlinks;
// export const selectVlinkById = (vlinkId: string) => (state: RootState) =>state.vlinks.vlinks.find((vlink: { id: string; }) => vlink.id === vlinkId);

// Export reducer
export default vlinkSlice.reducer;
