import { useCallback, useEffect, useRef, useState } from 'react';

const videos = [
    'https://player.vimeo.com/external/371433846.sd.mp4?s=236da2f3c0fd273d2c6d9a064f3ae35579b2bbdf&profile_id=164&oauth2_token_id=57447761',
    'https://player.vimeo.com/external/454667698.sd.mp4?s=a8d5cebb7aee26128beb954ef8f53827c5adde9b&profile_id=164&oauth2_token_id=57447761',
    'https://player.vimeo.com/external/473178018.sd.mp4?s=f37cb1af5b53d74bf9d2f6e9525f15646e4cba60&profile_id=164&oauth2_token_id=57447761',
    'https://player.vimeo.com/external/372085467.sd.mp4?s=7a8a61433e672c0c9038510eea7ec9e69f53194d&profile_id=164&oauth2_token_id=57447761',
    'https://player.vimeo.com/external/448550889.sd.mp4?s=1ebef2c77a24e64d5009a4562c6533afd27e253d&profile_id=165&oauth2_token_id=57447761'
];




export interface Vlink {
    id: string;
    url: string;
    start: number;
    end: number;
    vmakerId: string;
}



export default function VideoPlayer({ vlinks }: { vlinks: Vlink[] }) {
    const LINKTOVIDEO = 'https://res.cloudinary.com/dxq4veqsa/video/upload/'
    //  const LINKTOVIDEO = ''



    const modifiedVideos = vlinks

    const [myString, setMyString] = useState<String[]>(['block w-auto aspect-video', 'none'])

    const firstVid = useRef<HTMLVideoElement>(null)
    const secondVid = useRef<HTMLVideoElement>(null)
    const thirdVid = useRef<HTMLVideoElement>(null)

    const [indexVideo, setIndexVideo] = useState(0)
    const [previousNext, setPreviousNext] = useState(false)

    const [isPlaying, setIsPlaying] = useState(false)

  

    function handleStart() {
        // console.log('handelStart')
        const video = firstVid.current
        const video2 = secondVid.current
        if (video) {
            video.src = LINKTOVIDEO + modifiedVideos[0].url
            // console.log(video.src)
            video.currentTime = modifiedVideos[0].start
            setIndexVideo(0)
            setIsPlaying(false)
            if(video2){
                // console.log("video2")
                video2.src = LINKTOVIDEO + modifiedVideos[1].url
                video2.currentTime = modifiedVideos[1].start
            }
        }
        console.log(video2)
    }


    function handleTimeUpdt() {
        // console.log('handleTimeUpdt')
        const video = firstVid.current;
        const video2 = secondVid.current
        if(video2){
            // console.log("video2")
            video2.src = LINKTOVIDEO + modifiedVideos[1].url
            video2.currentTime = modifiedVideos[1].start
            // console.log(modifiedVideos)
        }
        if (video2 && modifiedVideos.length - 1 != indexVideo) {
            // console.log(indexVideo)
            video2.currentTime = modifiedVideos[indexVideo + 1].start
            // console.log(video2)
   
        }
       
        if (video && video2 && video?.currentTime >= modifiedVideos[indexVideo].end) {
            video.pause()
            if (modifiedVideos.length - 1 != indexVideo) {
                // console.log("isDifferent")
                setIndexVideo(indexVideo + 1)
            } else {
                // console.log('isNot')
                handleStart()

            }
        }
        console.log(video2)

    }

    useEffect(() => {


        const video = firstVid.current;
        const video2 = secondVid.current;


        if (indexVideo === 0 && video && video2) {
            video.currentTime = modifiedVideos[0].start
            video2.currentTime = modifiedVideos[1].start
            video2.src =LINKTOVIDEO+ modifiedVideos[1].url 

        } else {

            if (!previousNext) {
                if (video && indexVideo != 0) {
                    video.currentTime = modifiedVideos[indexVideo].start
                    video.play()
                    // console.log('differentOfPreviousNext')

                }
            } else {
                if (video) {
                    video.currentTime = modifiedVideos[indexVideo].start
                    // console.log('callToPause')
                    video?.pause()
                }
            }
        }

        console.log(video2)

    }, [indexVideo])

    useEffect(() => {
        const video = firstVid.current;
        if (video && modifiedVideos[indexVideo].url) {

            video.src = LINKTOVIDEO + modifiedVideos[indexVideo].url
            video.currentTime = modifiedVideos[0].start
        }
    }, [])

    useEffect(() => {
        handleStart()
        console.log('loadVlinks')
    }, [vlinks])


    if (vlinks.length === 0) {
        return <div>No videos available</div>;
    }



    return (
        <>
            <div className='flex flex-col gap-2 h-[60hv] w-[600px]' >

                <div className='border border-1 border-slate-400 rounded-lg aspect-video w-[600px]'>
                    {isPlaying === false ?
                        <div className='relative left-1/2  top-[40%]'>
                            <div className='cursor-pointer flex flex-col justify-center h-12 w-12 border border-2 border-slate-400 bg-white z-40 absolute rounded-full' onClick={() => { setPreviousNext(false); firstVid.current?.play(); setIsPlaying(true) }}>
                                <img className=' h-7 w-7 mx-auto ' src="/icon/player/play.svg" alt="" />
                            </div>
                        </div>
                        :
                        <div className='relative left-1/2  top-[40%]'>
                            <div className='cursor-pointer opacity-0 flex flex-col justify-center h-12 w-12 border border-2 border-slate-400 bg-white z-40 absolute rounded-full hover:opacity-100' onClick={() => { setPreviousNext(false); firstVid.current?.pause(); setIsPlaying(false) }}>
                                <img className=' h-7 w-7 mx-auto ' src="/icon/player/pause.svg" alt="" />
                            </div>
                        </div>
                    }

                    <video
                        ref={firstVid}
                        key={indexVideo}
                        src={LINKTOVIDEO + modifiedVideos[indexVideo].url}
                        style={{ display: myString[0].toString(), maxHeight: '700px', }}
                        onTimeUpdate={handleTimeUpdt}
                        className='rounded-lg w-[600px] aspect-video z-20 '
                    >
                        <div className='flex absolute py-auto self-center z-10 ' >
                            <div className='my-auto absolute z-50'>A</div>
                        </div>
                    </video>





{ modifiedVideos.length> 1 &&
                    <video
                        ref={secondVid}
                        key={indexVideo + 1}
                        //  src={LINKTOVIDEO + (modifiedVideos.length - 1 != indexVideo ? modifiedVideos[indexVideo + 1].url : modifiedVideos[0].url)}
                        src={
                            indexVideo + 1 >= modifiedVideos.length ?
                             LINKTOVIDEO + modifiedVideos[1].url
                            :
                            LINKTOVIDEO + modifiedVideos[indexVideo + 1].url}

                        style={{ display: myString[0].toString(), maxHeight: '700px', }}
                        className='z-0 rounded-lg w-[600px] aspect-video absolute opacity-0'
                    />}
                </div>

                {/* tester avec un seul useState pour voir niveau perf */}
                <div className='flex justify-center '>
                    {/* Todo: change the  design and not relative */}
                    <div className='flex w-64 gap-4 relative'>
                        <h1>
                            {indexVideo + 1}
                        </h1>
                        <button onClick={() => { setIndexVideo(0); firstVid.current!.pause(); setIsPlaying(false) }}>
                            Début
                        </button>
                        <button onClick={() => { setPreviousNext(false); firstVid.current?.play(); setIsPlaying(true) }}>
                            Play
                        </button>
                        <button onClick={() => { firstVid.current?.pause(); setIsPlaying(false) }}>
                            Pause
                        </button>
                        <button onClick={() => { setPreviousNext(true); indexVideo > 0 ? setIndexVideo(indexVideo - 1) : setIndexVideo(modifiedVideos.length - 1) }}>
                            Previous
                        </button>
                        <button onClick={() => { setPreviousNext(true); indexVideo < modifiedVideos.length - 1 ? setIndexVideo(indexVideo + 1) : setIndexVideo(0) }}>
                            Next
                        </button>
                    </div>
                </div>
                {/*  <button type="button" className="h-12 w-40 mx-auto mt-10 transition duration-200 bg-lime-600 hover:bg-lime-700 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
     onClick={() => navigate('/bobette/invitation')} >
                    <span className="inline-block text-white mr-2">Télécharger</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline-block">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                    </svg>


                </button> */}
            </div>
        </>
    );
}
