import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import vmakerSlice, { setVmakerInfo } from '../features/userInfo/vmakerSlice';
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import BurgerMenu from '../components/BurgerMenu';
import { generateEmailLink } from '../components/postContent';

interface IndexMakerProps {

}

export default function IndexMaker({ }: IndexMakerProps) {

    const [searchParams] = useSearchParams();

    const requestOptions: RequestInit = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Origin': 'http://localhost:3000',

        },

    };



    const navigate = useNavigate()
    const [bobetteId, setBobetteId] = useState<string>('')
    const [masterId, setMasterId] = useState<string>('')
    const [vmakerId, setVmakerId] = useState<string>('')
    const [userName, setUserName] = useState<string>('')
    const [masterName, setMasterName] = useState<string>('')
    const [vmakerName, setVmakerName] = useState<string>('')
    const dispatch = useDispatch()
    const currentName = 'anniversaire de Nina :)'


    function genRandonString(length: number) {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
        const charLength = chars.length;
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * charLength));
        }
        return result;
    }

    useEffect(() => {
      const url = window.location.href; // Assuming you're getting the URL from the browser
      const lastSlashIndex = url.lastIndexOf('/');
      const id = url.substring(lastSlashIndex + 1);


        // change bobette ID by a short uuid (shared to all vmakers) : https://www.npmjs.com/package/short-uuid
        // fetching the with ID of Bobette
        console.log(id)
        const participants = [
            {
              "id": "{90DD528E-3228-4A42-BBB0-0480BAFD4067}",
              "name": "Muriel",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "Murieldujardin@gmail.com",
              "participants": 2,
              "secondName": "Dujardin",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{0B1B5EF1-071D-47DA-99D4-08299641F009}",
              "name": "Joanne",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "joanne.deridder@hotmail.fr",
              "participants": 2,
              "secondName": "De Ridder",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{76A02E2E-8FC2-4AB2-92C1-0DF00421E8B6}",
              "name": "Natacha",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "hucklenbroich.nat@hotmail.com",
              "participants": 2,
              "secondName": "Hucklenbroich",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{4B45930B-744B-4B1F-A1FC-0EEEEBAB8BA6}",
              "name": "Aurélie",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "aurelie.cb31@gmail.com",
              "participants": 5,
              "secondName": "Cabu",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{43EA8E9C-FF19-4EF8-89E1-1B6188E5775B}",
              "name": "Chanelle",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "chanelle.etemadi@hotmail.be",
              "participants": 1,
              "secondName": "Etemadi",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{07A0C433-5D88-4B50-B0A4-2BC3090183A7}",
              "name": "Mathilde",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "equidk@gmail.com",
              "participants": 1,
              "secondName": "De Keyser",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{13295506-DC74-4318-AC6F-2E25BF25CDF2}",
              "name": "Gaël",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "gaelvd@gmail.com",
              "participants": 2,
              "secondName": "Van Dieren",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{7BCC73EE-D99D-4AD0-8354-35B68A12C673}",
              "name": "Savinien",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "saviniendehant@hotmail.com",
              "participants": 2,
              "secondName": "Dehant",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{3D6C27B7-007D-445E-A22B-4BC5DDA7BC0D}",
              "name": "Fanny",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "fanny.magain@hotmail.com",
              "participants": 1,
              "secondName": "Magain",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{AE92AC42-CC57-442D-9359-4F358D9C8F83}",
              "name": "Marine",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "marine.demesmaeker@outlook.com",
              "participants": 1,
              "secondName": "De Mesmaeker",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{563AE68D-3B23-4936-9052-5630DA51D2F8}",
              "name": "Marie-Françoise",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "mariefrancoisegoossens@gmail.com",
              "participants": 2,
              "secondName": "Goossens",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{D1A51760-95A4-4FF3-8EC0-594DF751F078}",
              "name": "Véronique",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "veroeyckermans@hotmail.com",
              "participants": 1,
              "secondName": "Eyckermans",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
             {
              "id": "{742FE8E5-D94D-44B7-B567-5FDDFB575946}",
              "name": "Jérôme",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "jer.defosse@gmail.com",
              "participants": 2,
              "secondName": "Defosse",
              "textIndex": 333,
              "": "",
              "__1": ""
            }, 
            {
              "id": "{1911DD95-89CE-48E0-AF1F-607B218394D8}",
              "name": "Lydie",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "lyly.servotte@hotmail.com",
              "participants": 2,
              "secondName": "Servotte",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{7B0C7095-0BDB-4029-8EC6-6CED5BB5E6C7}",
              "name": "Barbara",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "babs.schoenmakers@gmail.com",
              "participants": 2,
              "secondName": "Schoenmakers",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{6731AA5F-C3A8-4735-8767-73C9DBBA6057}",
              "name": "Martin",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "tombalmartin@gmail.com",
              "participants": 2,
              "secondName": "Tombal",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{9BFA8AA1-881B-45B8-A556-74CA17DEAAEF}",
              "name": "Mathilde",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "equidk@gmail.com",
              "participants": 1,
              "secondName": "De Keyser",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{583A1528-FC37-43ED-811C-7ECEB8BD2D73}",
              "name": "patricia",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "patricia.dehasque@skynet.be",
              "participants": 1,
              "secondName": "de HASQUE",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{DA193587-65D1-47D6-B92F-8CBD62EB3C32}",
              "name": "David",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "dvd.peeters@gmail.com",
              "participants": 1,
              "secondName": "Peeters",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{3F89A34B-88FE-45E4-B62A-8EF3D7BB1A7D}",
              "name": "Marc",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "94marc.petit@gmail.com",
              "participants": 1,
              "secondName": "Petit",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{2179081C-BA92-490A-8C68-A18D7A9042D8}",
              "name": "Marie-Françoise",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "mariefrancoisegoossens@gmail.com",
              "participants": 2,
              "secondName": "Goossens",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{40BBCFD9-A06B-46F2-A596-B2FA0E5D0F05}",
              "name": "Corentin",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "corentin.callewaert@skynet.be",
              "participants": 1,
              "secondName": "Callewaert",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{E87FA57E-557D-4430-A8CB-E95960FFE5A8}",
              "name": "Pierre",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "pierre.callewaert@skynet.be",
              "participants": 1,
              "secondName": "Callewaert",
              "textIndex": 333,
              "": "",
              "__1": ""
            },
            {
              "id": "{DFBF1C4F-CF69-4493-AE38-F7A00207893B}",
              "name": "Elodie",
              "bobetteId": "{FBA83867-8B32-4C7B-99DE-91F626287A43}",
              "masterId": "{B21D0568-0E48-4CCB-BE3E-B07B19CB7696}",
              "email": "Elodie.callewaert@skynet.be",
              "participants": 1,
              "secondName": "Callewaert",
              "textIndex": 333,
              "": "",
              "__1": ""
            }
          ]

        if(id && id.length> 15 )
           fetch(`https://bobette2022-002-site1.ftempurl.com/createVmaker?bobetteId=${id}&vmakerName=${genRandonString(5)}&vmakerSecondName=${genRandonString(5)}&vmakerEmail=${genRandonString(5)}&vmakerParticipants=1`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Origin': 'http://localhost:3000',

            },

        })
            .then(response => response.json())
            .then(function (data) {
                console.log(data);
                dispatch(setVmakerInfo({ value: { vmakerId: data.id, bobetteId: bobetteId, masterId: masterId } }))
            })


       /*  fetch(`https://bobette2022-002-site1.ftempurl.com/getOneVmaker/${id ? id : ''}`, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                console.log(data);
                // firstTry
                setBobetteId(data.bobetteId)
                setMasterId(data.masterId)
                setUserName(data.name)
              //  fetchMasterName(data.masterId)
                dispatch(setVmakerInfo({ value: { vmakerId: data.id, bobetteId: data.bobetteID, masterId: data.masterID, email: data.email, participants: data.participants, secondName: data.secondName, textIndex: 333, } }))
            })
 */


    }, [])

   /*  function fetchMasterName(masterId: string) {
        fetch(`https://bobette2022-002-site1.ftempurl.com/getMaster/${masterId}`, requestOptions)
            .then(response => response.json())
            .then(function (data) { setMasterName(data.name) })
    } */


    // move that fct to the end; the use here is counting the number of connections and cancel before recording
    // add input name not random
    function createVmaker() {
       


    }



   


      
    

useEffect(() => {
 // postContent()
}, [])









    return (
        <>

            <div className='flex flex-col gap-6 h-full'>

{/* 
                <div className='absolute -z-20  ml-1'>
                    <img src='./../Vector.svg' ></img>
                </div>
                <div className='absolute -z-10 mt-52  pl-8 pr-4'>
                    <img src='./../fluo.png' ></img>
                </div> */}
                <div className='flex flex-col mx-auto  gap-4'>
                <h2 className="text-3xl  font-bold tracking-tight text-gray-900 sm:text-4xl mx-auto">
                Salut <span className='text-black'>{userName} !</span> {/* { userName } de {masterName} */}
           
              
                    </h2>
                    <h2 className="text-2xl  font-bold tracking-tight text-gray-900 sm:text-2xl mx-auto text-center">

                    Bienvenu·e dans la Bobette {currentName} !
                    </h2>

                    <h1 className=' font-sans text-white text-5xl font-light px-4 leading-[4rem] drop-shadow-lg pt-4'>
                      
                    </h1>
                </div>

                <div className=" flex mx-auto my-5 h-2/3 mt-10">
                    <img className='card bg-transparent shadow-xl object-scale-down 	h-80' src="/nina.jpeg" alt="" />
                </div>

                <div className="card w-5/6 bg-cyan-200 shadow-xl flex mx-auto my-5 h-2/3">
                    <div className="card-body">
                        <h2 className="card-title text-primary-content">Créer une vidéo maintenant !</h2>
                        <p className='text-primary-content'>Quoi de mieux qu'une vidéo collaborative pour ce bel évènement :)</p>
                        <div className="card-actions justify-end">
                            <button className="bg-colorB1 text-white drop-shadow-lg p-3 rounded-lg text-slate-100 uppercase" onClick={() => navigate('/steps')}>C'est parti !</button>
                        </div>
                    </div>
                </div>

                <div className='font-mono my-0 flex justify-center w-full mb-10'>
                    {/* <div className='flex-col pt-0  px-4  rounded-lg h-42 drop-shadow-lg'>
                        <p className='pb-2 '>Il vous reste : </p>
                        <div className='py-1 flex justify-start'>
                            {/*  <div className='h-2 w-2'></div> 
                            <FlipClockCountdown
                                to={new Date().getTime() + 24 * 3600 * 1000 + 5000}
                                labels={['JOURS', 'HEURES', 'MINUTES', 'SECONDES']}
                                labelStyle={{ fontSize: 10, fontWeight: 500, textTransform: 'uppercase', color: 'white' }}
                                digitBlockStyle={{ width: 20, height: 30, fontSize: 26, backgroundColor: 'white', color: 'black' }}
                                dividerStyle={{ color: 'white', height: 1 }}
                                separatorStyle={{ color: 'white', size: '6px' }}
                                duration={0.5}

                            >
                                Finished
                            </FlipClockCountdown>
                            {/*   <div className='h-2 w-2'></div> 
                        </div>
                        <p className='p-0'>pour participer ! </p>


                    </div> */}






                </div>



                {/* <img className='h-12 w-12' src='./../video-camera.svg'></img>
                    <img className='h-12 w-12' src='./../group.svg'></img>
 */}

            </div>


        </>

    )
}


