import React from "react";
import EditingBobette from "../../pages/EditingBobette";
import Invitation from "../../pages/Invitation";
import FinalisingBobette from "../../pages/FinalisingBobette";

interface Props {
    actualTab: 'editing' | 'invite' |'finalised';
  }

const MemoizedTabs = ({ actualTab }:Props) => {
    return (
      <div className='flex flex-col w-full'>
        {actualTab === 'editing' && <EditingBobette />}
        {actualTab === 'invite' && <Invitation />}
        {actualTab === 'finalised' && <FinalisingBobette/>}

      </div>
    );
  };

  export default MemoizedTabs;