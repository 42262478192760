import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type { RootState } from '../app/store'
import Axios from 'axios'
import { Cloudinary, CloudinaryVideo, Transformation } from '@cloudinary/url-gen';
import { concatenate, trim } from '@cloudinary/url-gen/actions/videoEdit';
import { source } from '@cloudinary/url-gen/actions/overlay';
import { fill, fillPad, pad, scale } from '@cloudinary/url-gen/actions/resize';
import { videoSource as concatVideoSource } from "@cloudinary/url-gen/qualifiers/concatenate";
import { videoSource as tVideoSource, videoSource } from "@cloudinary/url-gen/qualifiers/transition.js";
import { AdvancedVideo } from '@cloudinary/react';
import { ifCondition } from '@cloudinary/url-gen/actions/conditional';
import { color } from '@cloudinary/url-gen/qualifiers/background';
import { selectCurrentVmaker } from '../features/userInfo/vmakerSlice';
import { setBlobArray } from '../features/blob/blobSlice';
import { selectLogs, setLogs } from "../features/mobileLogs/logsSlice";



export default function PreviewRecorded() {

  const currentBlob = useSelector((state: RootState) => state.currentBlob.value)
  const selectVideoLink = useSelector((state: RootState) => state.currentVideoLink)
  const selectCurrentVmakerInfo = useSelector(selectCurrentVmaker)
  const selectMyCurrentLogs = useSelector(selectLogs)
  const navigate = useNavigate()


  useEffect(() => {
    console.log(selectCurrentVmakerInfo)
    setVideo()
  }, [])

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'dxq4veqsa',
    }
  });

  const [myVideo, setMyVideo] = useState<CloudinaryVideo>(cld.video(''))

  const [isMobileRecord, setIsMobileRecord] = useState<boolean>(false)
  const [progressVal, setProgressVal] = useState<number>(0)

  const [isUploaded, setIsUploaded] = useState(false)

  const [connectionError, setConnectionError] = useState<string>('')


  let myVideoOne = cld.video('')
  let url: string|URL = ''
  
  function setVideo() {
    // or video url from cloudinary (ios input) if blob doesn't exist
    console.log(selectVideoLink);
    console.log(currentBlob)
    const video: any = document.getElementById("video-replay"); 

    url = currentBlob.length > 0 ? URL.createObjectURL(currentBlob[0]) : new URL('https://res.cloudinary.com/dxq4veqsa/video/upload/' + selectVideoLink.value);
    video.src = url
  }

  function handleUpload() {
    if(url.toString().includes('cloudinary')){
      // upload to DB from iphone
      createVlink(selectVideoLink.value, selectVideoLink.duration)
    }else{
       // upload to cloudinary and then to DB
       const formData = new FormData()
       formData.append('file', currentBlob[0])
       formData.append("upload_preset", "gzllmk5l")
       Axios.post("https://api.cloudinary.com/v1_1/dxq4veqsa/upload", formData, {
         onUploadProgress: progressEvent => {
           const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
           console.log(percentCompleted);
           setProgressVal(progressVal + percentCompleted)
         }
       })
         .then((response) => {
          console.log(response)
           console.log('v' + response.data.version + '/' + response.data.public_id)
           createVlink('v' + response.data.version + '/' + response.data.public_id, response.data.duration )
           if (response.data.public_id) {
             setIsUploaded(true)
           }
           setProgressVal(0)
         })
         .catch((error) => {
           console.log('hey error')
           setConnectionError('il y a eu une erreur de connection, veuillez réessayer')
           setProgressVal(0)
         }) 
    }

  }




  // Ajouter l'id du maker dans le store

  function createVlink(linkString: string, duration:number) {

    fetch(`https://bobette2022-002-site1.ftempurl.com/createVlink?vmakerId=${selectCurrentVmakerInfo.vmakerId}&url=${linkString}&start=0&end=0&duration=${duration}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Origin': 'http://localhost:3000',

      },

    })
      .then(response => response.json())
      .then(function (data) { 
        console.log(data)
        setIsUploaded(true)
        setProgressVal(0)
       })
  }


  // enregistrer la réponse dans bobette vLink, cette  string : 
  // v + data.version + / + data.public_id

  const vSource = 'v1662895615/bnqrsaffanykaciaysj1'

  const vSource2 = 'v1662918841/f16uouxa7rbrpe3rqtfo'



  // .videoEdit(
  //   concatenate(
  //     concatVideoSource(vSource),
  //   )
  // )
  // .videoEdit(
  //   concatenate(
  //     concatVideoSource(vSource2),
  //   ),
  // )

  function handleEdit() {
    fetch(`https://bobette2022-002-site1.ftempurl.com/getAllUrls?bobetteId=e2cbc682-4adb-422a-bf0d-d4aaba04fd46`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Origin': 'http://localhost:3000',

      },

    })
      .then(response => response.json())
      .then(function (data) {
        console.log(data)
        const otherData = ['v1669834681/za8pdtonaj2k99jfjyqs', 'v1663407787/q7sgjoakjk8fold41ixd', 'v1663407722/fj39zk7oo7lucvshglds']
        concatURLVideos(otherData)
      })

  }


  /* CONCAT IN NODEJS:  */
  /* https://mediajams.dev/post/Combine-multiple-videos-snippets-into-one-video */




  // phone https://res.cloudinary.com/dxq4veqsa/video/upload/v1669834681/za8pdtonaj2k99jfjyqs.webm
  // 16/9 : https://res.cloudinary.com/dxq4veqsa/video/upload/v1663407787/q7sgjoakjk8fold41ixd.webm





  function concatURLVideos(data: Array<string>) {
    const videoTransformArray = []
    for (let i = 1; i < data.length; i++) {
      /*  Loop and start at index 1:      'fl_splice,l_video:' + data[i].split('/')[0] + ':'+ data[i].split('/')[1]+'fl_layer_apply/' 
          Push at the end data[0]: 'v1669834681/za8pdtonaj2k99jfjyqs'     
          AddToArray & join
          Total: 'https://res.cloudinary.com/dxq4veqsa/video/upload/if_ar_lt_1.0/b_darkorange,c_pad,h_720,w_1280/if_end/' + joinedArray 
      */
      const prepareLink = 'fl_splice,l_video:' + data[i].split('/')[0] + ':' + data[i].split('/')[1] + '/fl_layer_apply/'
      videoTransformArray.push(prepareLink)
      // links with different formats: 
      // https://res.cloudinary.com/dxq4veqsa/video/upload/v1673558419/Pexels_Videos_2786540_uufsku.mp4
      // https://res.cloudinary.com/dxq4veqsa/video/upload/v1673558208/pexels-kostas-exarhos-10631962_1_g3e8ww.mp4
      // https://res.cloudinary.com/dxq4veqsa/video/upload/v1668459725/qetxyw4z2v8kenk6sz3x.mkv
      // https://res.cloudinary.com/dxq4veqsa/video/upload/v1663407787/q7sgjoakjk8fold41ixd.webm
      // 
      // working, eo_6,so_1 start at 1 end at 6:
      // https://res.cloudinary.com/dxq4veqsa/video/upload/eo_6,so_1/c_fill,h_200,w_300/fl_splice,l_video:v1673558419:Pexels_Videos_2786540_uufsku/eo_6,so_1/c_fill,h_200,w_300/fl_layer_apply/v1668459725/qetxyw4z2v8kenk6sz3x.mp4

      // https://res.cloudinary.com/dxq4veqsa/video/upload/eo_6,so_1/c_fill,h_200,w_300/fl_splice,l_video:v1676120543:plfw1oootfz3qqoudrsw.webm/eo_6,so_1/c_fill,h_200,w_300/fl_layer_apply/v1676120329/mslb9whow0g6mivlgftd.mp4

      // h_720,w_1280
      // ******************
      // https://res.cloudinary.com/dxq4veqsa/video/upload/c_fill,h_720,w_1280/fl_splice,l_video:v1679828374:lsmazj1js6aiazwtxykj/c_fill,h_720,w_1280/fl_splice,l_video:v1679776232:mrr0p01ixjr2ndsqaix3/c_fill,h_720,w_1280/fl_layer_apply/v1679775301/rgawagmduoyueuuxlneg.mov
      // ******************

      // /c_fill,h_720,w_1280/fl_splice,l_video:
      // https://res.cloudinary.com/dxq4veqsa/video/upload/c_fill,h_720,w_1280/fl_splice,l_video:v1679833098:ik5uqh7zxmpozhemq97z/c_fill,h_720,w_1280/fl_splice,l_video:v1679835659:okyksqu7d6qxgjs3v7h5/c_fill,h_720,w_1280/fl_splice,l_video:v1679838245:ec25rcvfoohzslwgitfj_1_jbmv8o/c_fill,h_720,w_1280/fl_splice,l_video:v1679837208:l2capadchpl3vttbbuq5/c_fill,h_720,w_1280/fl_splice,l_video:v1679775301:rgawagmduoyueuuxlneg/c_fill,h_720,w_1280/fl_splice,l_video:v1679776232:mrr0p01ixjr2ndsqaix3/c_fill,h_720,w_1280/fl_layer_apply/v1679832370/ybgjuh209coi7gh8u9so.mp4

      // https://res.cloudinary.com/dxq4veqsa/video/upload/eo_5,so_0/v1679834212/ec25rcvfoohzslwgitfj.mkv
      
      // LauLau et Steph: v1679833098:ik5uqh7zxmpozhemq97z
      // Ben: v1679835659:okyksqu7d6qxgjs3v7h5
      // Bullet: v1679834212:ec25rcvfoohzslwgitfj
      // Ben: v1679837208:l2capadchpl3vttbbuq5
      // Mère : v1679775301:rgawagmduoyueuuxlneg
      // Marine: v1679776232:mrr0p01ixjr2ndsqaix3
      // Robin: v1679832370/ybgjuh209coi7gh8u9so

      // -> v1676120543/plfw1oootfz3qqoudrsw.webm
      // -> v1676120329/mslb9whow0g6mivlgftd.webm

      // v1663407787/q7sgjoakjk8fold41ixd

      // Patern to add a video in the list:
      // c_fill,h_200,w_300/fl_splice,l_video:v1663407787:q7sgjoakjk8fold41ixd

      // Patern to add the first video (end of the link)
      // fl_layer_apply/v1668459725/qetxyw4z2v8kenk6sz3x.mp4

      // https://res.cloudinary.com/dxq4veqsa/video/upload/c_fill,h_200,w_300/fl_splice,l_video:v1673558419:Pexels_Videos_2786540_uufsku/c_fill,h_200,w_300/fl_splice,l_video:v1663407787:q7sgjoakjk8fold41ixd/c_fill,h_200,w_300/fl_layer_apply/v1668459725/qetxyw4z2v8kenk6sz3x.mp4
      // v1673558208/pexels-kostas-exarhos-10631962_1_g3e8ww
      //  c_fill,h_200,w_300/fl_splice,l_video:v1673558208:pexels-kostas-exarhos-10631962_1_g3e8ww
      // https://res.cloudinary.com/dxq4veqsa/video/upload/c_fill,h_200,w_300/fl_splice,l_video:v1673558419:Pexels_Videos_2786540_uufsku/c_fill,h_200,w_300/fl_splice,l_video:v1673558208:pexels-kostas-exarhos-10631962_1_g3e8ww/c_fill,h_200,w_300/fl_splice,l_video:v1663407787:q7sgjoakjk8fold41ixd/c_fill,h_200,w_300/fl_layer_apply/v1668459725/qetxyw4z2v8kenk6sz3x.mp4
      // 

      /*
            let url = data[i];
           
            let isVideoPhone = cld.video(url)
                .conditional(
                ifCondition(
                  "aspect_ratio < 1.0", 
                  new Transformation().resize(
                    pad().width(640).height(480).background(color("darkorange"))
                  )
                )
              );   
      
              console.log(isVideoPhone.toURL())
              console.log(isVideoPhone)
              videoTransformArray.push(isVideoPhone.toURL().split('upload/')[1])
             // push url isVideoPhone. 
             // 'if_ar_lt_1.0/b_darkorange,c_pad,h_720,w_1280/if_end/'
      
             // https://res.cloudinary.com/dxq4veqsa/video/upload/if_ar_lt_1.0/b_darkorange,c_pad,h_720,w_1280/if_end/fl_splice,l_video:v1663407787:q7sgjoakjk8fold41ixd/fl_layer_apply/fl_splice,l_video:v1663407722:fj39zk7oo7lucvshglds/fl_layer_apply/v1669834681/za8pdtonaj2k99jfjyqs.webm?_a=ATRSRAA0
             // https://res.cloudinary.com/dxq4veqsa/video/upload/if_ar_lt_1.0/b_darkorange,c_pad,h_720,w_1280/if_end/fl_splice,l_video:v1663407787:q7sgjoakjk8fold41ixd/fl_layer_apply/fl_splice,l_video:v1663407722:fj39zk7oo7lucvshglds/fl_layer_apply/v1669834681/za8pdtonaj2k99jfjyqs.webm?_a=ATRSRAA0
             // https://res.cloudinary.com/dxq4veqsa/video/upload/if_ar_lt_1.0/b_darkorange,c_pad,h_720,w_1280/if_end/fl_splice,l_video:v1663407787:q7sgjoakjk8fold41ixdfl_layer_apply/fl_splice,l_video:v1663407722:fj39zk7oo7lucvshglds/fl_layer_apply/v1669834681/za8pdtonaj2k99jfjyqs                                                                                                                                                                          :fj39zk7oo7lucvshglds/fl_layer_apply' [0]
                https://res.cloudinary.com/dxq4veqsa/video/upload/if_ar_lt_1.0/b_darkorange,c_pad,h_720,w_1280/if_end/fl_splice,l_video:v1669834681:za8pdtonaj2k99jfjyqs/fl_layer_apply/fl_splice,l_video:v1663407722:fj39zk7oo7lucvshglds/fl_layer_apply/v1663407787/q7sgjoakjk8fold41ixd                                                                                                                                                                       
      
      
             if (i === 0) {
              myVideoOne = isVideoPhone
            } else { 
              myVideoOne.videoEdit(
                concatenate(
                  concatVideoSource('if_ar_lt_1.0/b_darkorange,c_pad,h_720,w_1280/if_end/fl_splice,l_video:' +url.split('/')[0] + ':' +url.split('/')[1]+'/fl_layer_apply/')
                )
              )
              */
    }
    console.log(videoTransformArray)
    const stringSplicedArray = videoTransformArray.join('')
    const linkResulted = 'https://res.cloudinary.com/dxq4veqsa/video/upload/if_ar_lt_1.0/b_darkorange,c_pad,h_720,w_1280/if_end/' + stringSplicedArray + data[0]
    console.log(linkResulted)
    setMyVideo(myVideoOne)

    console.log(currentBlob);
  }


  return (
    <>
      <div className='h-screen'>
        {isUploaded ? ''
          :
          <>
            <div className="flex justify-center">
              

              <h2 className=" w-48 z-10 pt-3 text-secondary-content">Ma vidéo :</h2>
            </div>
            <video className='mt-10 p-2' id="video-replay" height={progressVal > 0 ? '0' : '480'} width={progressVal > 0 ? '0' : '640'} controls ></video>
            <div className='flex flex-col gap-4 self-center'>
              {progressVal > 0 ? '' :
                <>
                  <button className="w-48 mt-4 self-center bg-colorB1 text-white drop-shadow-lg p-3 rounded-lg text-slate-100 uppercase flex justify-around" onClick={handleUpload}>Envoyer</button>
                 {/*  <button className="w-48 mt-4 self-center bg-colorB1 opacity-20 text-white drop-shadow-lg p-3 rounded-lg text-slate-100 uppercase flex justify-around" onClick={() => ''} disabled>Modifier avant d'envoyer</button> */}
                 <button className="w-48 mt-4 self-center bg-slate-400 text-white drop-shadow-lg p-3 rounded-lg text-slate-100 uppercase flex justify-around" onClick={()=>navigate(-1)}>Retour</button>

                </>
              }
            </div>
          </>
        }
        {progressVal > 0 ?
          <div className='flex flex-col mx-auto w-2/3 gap-2'>

            <h2>Video en cours d'envoie, ne pas quitter la page</h2>
            <p className="self-center">{progressVal}</p>
            <progress className="progress progress-info w-56 m-2" value={progressVal} max="100"></progress>
          </div>
          : ''
        }
        {connectionError != '' ?
          <div>
            {connectionError}
          </div>
          : ''
        }

        {isUploaded ?
          <>
            <h2 className='flex flex-col mx-auto w-2/3 gap-2 mt-8'>Video envoyée à la base de donnée de Laurent ! L'assemblage sera effectué après réception de tous les témoignages.  </h2>
            <img className='p-3' src="https://media.giphy.com/media/W0DimFzOiPQV1uVmaE/giphy.gif" width="480" height="287" />
          </>
          :
          ''
        }

      </div>

    </>
  )
}