import { configureStore } from '@reduxjs/toolkit'
import blobReducer from '../features/blob/blobSlice'
import vmakerReducer from '../features/userInfo/vmakerSlice'
import masterReducer from '../features/userInfo/masterSlice'
import bobetteReducer from '../features/userInfo/bobetteSlice'
import logsReducer from '../features/mobileLogs/logsSlice'
import videoLinkReducer from '../features/ios/videoLinkSlice'
import vlinksReducer from '../features/userInfo/vlinksSlice'

export const store = configureStore({
  reducer: { 
    currentBlob: blobReducer, 
    currentVmaker: vmakerReducer, 
    currentMaster: masterReducer, 
    currentBobette: bobetteReducer, 
    currentLogs: logsReducer, 
    currentVideoLink: videoLinkReducer, 
    currentVLinks:vlinksReducer 
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch