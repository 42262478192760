import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';


export interface IExplain {
    projectName: string;
    receiverName: string;
    receiverSecondName: string;
    deadline: Date | string;
}

export interface IInvitation {
    image: string;
    invitationTitle: string;
    invitationDescription: string;
}

export interface IBobette {
    masterId: string;
    bobetteId: string;
    typeOfEvent: string;
    projectName: string;
    receiverName: string;
    receiverSecondName: string;
    deadline: Date | string;
    image: string;
    invitationTitle: string;
    invitationDescription: string;
    vlinks:string[]
}

const initialState: IBobette = {
    masterId: '',
    bobetteId: '',
    typeOfEvent: '',
    projectName: '',
    receiverName: '',
    receiverSecondName: '',
    deadline: '',
    image: '',
    invitationTitle: '',
    invitationDescription: '',
    vlinks:['']
}

export const bobetteSlice = createSlice({
    name: 'currentBobette',
    initialState,
    reducers: {
        setTypeOfEvent: (state, action: PayloadAction<string>) => {
            state.typeOfEvent = action.payload
        },
        setExplain: (state, action: PayloadAction<IExplain>) => {
            state.projectName = action.payload.projectName
            state.receiverName = action.payload.receiverName
            state.receiverSecondName = action.payload.receiverSecondName
            state.deadline = action.payload.deadline
        },
        setInvitation: (state, action: PayloadAction<IInvitation>) => {
            state.image = action.payload.image
            state.invitationTitle = action.payload.invitationTitle
            state.invitationDescription = action.payload.invitationDescription
        },
        setVlinks: (state, action: PayloadAction<string[]>) =>{
            state.vlinks = action.payload
        },
        setCurrentBobetteId: (state, action: PayloadAction<string>) =>{
            state.bobetteId = action.payload
        },
    }

})


export const { setTypeOfEvent, setExplain, setInvitation, setVlinks,setCurrentBobetteId } = bobetteSlice.actions

export const selectVlinks = (state:RootState) => state.currentBobette.vlinks
export const selectInvitationTitle = (state:RootState) => state.currentBobette.invitationTitle
export const selectInvitationDescription = (state:RootState) => state.currentBobette.invitationDescription
export const selectBobetteId = (state:RootState) => state.currentBobette.bobetteId



export default bobetteSlice.reducer