import React, { useState } from 'react';
import { HashRouter, Routes, Route, useNavigate, BrowserRouter } from 'react-router-dom'
import { StartYourRecording } from './pages/StartYourRecording';
import PreviewRecorded from './pages/PreviewRecorded';
import IndexMaker from './pages/IndexMaker';
import BurgerMenu from './components/BurgerMenu';
import Steps from './pages/Steps';
import EditingBobette from './pages/EditingBobette';
import CreateMaker from './pages/CreateMaker';
import { Bars3Icon } from '@heroicons/react/20/solid';
import BobetteStatus from './pages/BobetteStatus';
import P1_Create from './pages/MasterPages/P1_Create';
import P2_Explain from './pages/MasterPages/P2_Explain';
import P3_Connexion from './pages/MasterPages/P3_Connexion';
import P4_Invitation from './pages/MasterPages/P4_Invitation';
import P5_BobetteSaved from './pages/MasterPages/P5_BobetteSaved';
import P7_Dashboard from './pages/MasterPages/P7_Dashboard';
import P6_MyBobettes from './pages/MasterPages/P6_MyBobettes';




function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (



    <>
      <header className="bg-slate-100 rounded-lg">
        <nav className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5 flex">
              {/* s */}
              <img className="h-14 w-auto" src="/logo.png" alt="" />
            </a>
          </div>

          <div className="flex ">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" />
            </button>
          </div>
        </nav>

      </header>

      <div className='px-4 bg-slate-100 rounded-lg h-screen'>
      <BrowserRouter>
      
          <Routes>
            <Route path='/' element={<IndexMaker />} />
            <Route path='/steps' element={<Steps />} />
            <Route path='/startRecording' element={<StartYourRecording />} />
            <Route path='/previewRecorded' element={<PreviewRecorded />} />
            <Route path='/edit' element={<EditingBobette />} />
            <Route path='/status' element={<BobetteStatus />} />
            <Route path='/createMaker' element={<CreateMaker />} />


            <Route path='/bobette/create' element={<P1_Create />} />
            <Route path='/bobette/explain' element={<P2_Explain />} />
            <Route path='/bobette/connexion' element={<P3_Connexion />} />
            <Route path='/bobette/invitation' element={<P4_Invitation />} />
            <Route path='/bobette/saved' element={<P5_BobetteSaved />} />
            <Route path='/bobette/projects' element={<P6_MyBobettes />} />
            <Route path='/bobette/dashboard' element={<P7_Dashboard />} />




            <Route path='*' element={<IndexMaker />} />
          </Routes>
          </BrowserRouter>
      </div>
    </>

  )
}


export default App;
