import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import React, { useState } from 'react'

interface CreateMakerProps {

}

const callouts = [
    {
        name: 'Desk and Office',
        description: 'Work from home accessories',
        imageSrc: '/4.png',
        imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
        href: '#',
    },
    {
        name: 'Self-Improvement',
        description: 'Journals and note-taking',
        imageSrc: '/2.png',
        imageAlt: 'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
        href: '#',
    },
    {
        name: 'Travel',
        description: 'Daily commute essentials',
        imageSrc: '/3.png',
        imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
        href: '#',
    },
]

interface createMakerForm {
    vmakerSecondName: string;
    vmakerName: string;
    vmakerEmail: string;
    vmakerParticipants: number;
}

export default function CreateMaker({ }: CreateMakerProps) {

    const requestOptions: RequestInit = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Origin': 'http://localhost:3000',

        },

    };

    const [formData, setFormData] = useState<createMakerForm>({ vmakerSecondName: '', vmakerName: '', vmakerEmail: '', vmakerParticipants: 1 });

    const [errorFields, setErrorFields] = useState('')

    const [isSubmitedSuccess, setIsSubmitedSuccess] = useState(false)

    const validateAndSend = () => {
        console.log(formData)
        const formKeys = Object.values(formData);
        for (const key in formKeys) {
            if (formKeys[key].length <= 1) {
                setErrorFields('Assurez-vous que vous avez rempli tous les champs')
                return
            }
        }
        try {
            fetch(`https://bobette2022-002-site1.ftempurl.com/createVmaker?bobetteId=${'fba83867-8b32-4c7b-99de-91f626287a43'}&vmakerName=${formData.vmakerName}&vmakerSecondName=${formData.vmakerSecondName}&vmakerEmail=${formData.vmakerEmail}&vmakerParticipants=${formData.vmakerParticipants}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                    'Origin': 'http://localhost:3000',

                },

            })
                .then(response => response.json())
                .then((data) => {
                    console.log(data)
                    setIsSubmitedSuccess(true)
                })

        } catch (error) {
            setErrorFields('une erreur s\'est produite, réessayer')
        }


    }

    return (

        <div className="border-b border-gray-900/10 pb-12  ">

            <div className='flex flex-col'>
                <div className="">
                    <div className='flex justify-center flex-col'>

                        <h2 className=" text-xl text-center  font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight sm:text-center">Bienvenue dans la Bobette</h2>
                        <div className='flex gap-2 justify-center'>
                            <h2 className=" text-xl text-right pl-8  font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight sm:text-center"> d'Hélène et Martin !</h2>
                            <img className='h-14' src="/5.png" alt="" />
                        </div>


                    </div>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-16">

                            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                                {callouts.map((callout) => (
                                    <div key={callout.name} className="group relative ">
                                        <div className="relative h-80 flex justify-center">
                                            <img
                                                src={callout.imageSrc}
                                                alt={callout.imageAlt}
                                                className="h-full  object-contain  border border rounded-lg shadow-lg"
                                            />
                                        </div>
                                        {/*  <h3 className="mt-6 text-sm text-gray-500">
                                            <a href={callout.href}>
                                                <span className="absolute inset-0" />
                                                {callout.name}
                                            </a>
                                        </h3>
                                        <p className="text-base font-semibold text-gray-900">{callout.description}</p> */}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex  w-full p-10 '>
                    <div className='lg:flex justify-center  w-full md:shrink-0 px-6'>
                        <img src="/draw1.png" alt="" />
                        {isSubmitedSuccess ?
                            <div className="rounded-md bg-green-50 p-4 h-14">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-green-800">Vous êtes maintenant inscrit, merci !</p>
                                    </div>
                                    <div className="ml-auto pl-3">
                                        <div className="-mx-1.5 -my-1.5">
                                            <button
                                                type="button"
                                                className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                            >
                                                <span className="sr-only">Dismiss</span>
                                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className=" flex-col">
                                <h2 className="text-md font-bold text-gray-900 self-center p-4">S'inscrire à la vidéo collaborative</h2>
                                <div className='flex flex-col gap-2'>
                                    <div className="lg:col-start-3 sm:col-span-1">
                                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Nom
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="last-name"
                                                id="last-name"
                                                autoComplete="family-name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={formData.vmakerSecondName || ''}
                                                onChange={(e) => { setFormData({ ...formData, vmakerSecondName: e.target.value }); setErrorFields('') }}
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Prénom
                                        </label>
                                        <div className="mt-2 ">
                                            <input
                                                type="text"
                                                name="first-name"
                                                id="first-name"
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={formData.vmakerName || ''}
                                                onChange={(e) => { setFormData({ ...formData, vmakerName: e.target.value }); setErrorFields('') }}
                                            />
                                        </div>
                                    </div>



                                    <div className="">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Adresse email
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={formData.vmakerEmail || ''}
                                                onChange={(e) => { setFormData({ ...formData, vmakerEmail: e.target.value }); setErrorFields('') }}
                                            />
                                        </div>
                                    </div>

                                    <div className='flex justify-between'>

                                        <div className="w-44">
                                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                                Nombre de participants
                                            </label>
                                            <div className="mt-2 w-38 pr-2">
                                                <select
                                                    id="country"
                                                    name="country"
                                                    autoComplete="country-name"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                    value={formData.vmakerParticipants || ''}
                                                    onChange={(e) => { setFormData({ ...formData, vmakerParticipants: parseInt(e.currentTarget.value) }); setErrorFields('') }}
                                                >
                                                    <option label='1' >1</option>
                                                    <option label='2' >2</option>
                                                    <option label='Plus que 2'>3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="h-12 self-end rounded-full bg-indigo-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            onClick={validateAndSend}
                                        >
                                            S'inscrire
                                        </button>
                                    </div>
                                    <p>
                                        {errorFields}
                                    </p>
                                </div>

                            </div>
                        }
                    </div>

                </div>




            </div>
        </div>
    )
}
